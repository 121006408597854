import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import useToast, { Type } from '../../hooks/useToast'
import Button from '../Button/Button'

type Props = { data: any; upsert: (data: any) => any; hasChanged: boolean; successCallback: (data: any) => void; cancelLink?: string }

const Save: React.FC<Props> = ({ data, upsert, hasChanged, successCallback, cancelLink }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()

  useEffect(() => {
    if (hasChanged && !window.onbeforeunload) {
      window.onbeforeunload = (event) => {
        const e = event || window.event
        // Cancel the event
        e.preventDefault()
        if (e) {
          e.returnValue = '' // Legacy method for cross browser support
        }
        return '' // Legacy method for cross browser support
      }
    } else {
      window.onbeforeunload = null
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [hasChanged])

  const save = async () => {
    const result = await upsert(data)
    if (!!result) {
      successCallback(result)
    } else {
      toast(t.UI.save.error, Type.ERROR)
    }
  }

  return (
    <div className="fixed bottom-0 left-0 w-full p-3 bg-turquoise flex justify-end items-center gap-5">
      {hasChanged && <div className="text-white">{t.generic.unsavedChanges}</div>}
      {!!cancelLink && <Button inverted href={cancelLink} text={t.generic.cancel} />}
      <Button inverted onClick={save} text={t.generic.save} />
    </div>
  )
}

export default Save
