export enum ValidationProp {
  BASE = 'BASE',
  STEUER = 'STEUER',
  KAPITALISIERUNG = 'KAPITALISIERUNG',
  BANK = 'BANK',
  LOGO = 'LOGO',
  MEHRHEIT = 'MEHRHEIT',
  ADMINISTRATOREN = 'ADMINISTRATOREN',
  EINLADUNGSFRIST = 'EINLADUNGSFRIST',
  INACTIVITY = 'INACTIVITY',
}

export enum Required {
  NEEDED = 'NEEDED',
  OPTIONAL = 'OPTIONAL',
}

export type Property = {
  name: string
  required: Required
}
