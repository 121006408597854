import Form from '../UI/Form/Form'
import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { auth } from '@think-internet/zeus-frontend-package'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import props from '../../redux/props'
import { Level } from '../../types/Auth'
import Button from '../UI/Button/Button'
import Input from '../UI/Input/Input'
import useToast from '../hooks/useToast'
import logo from '../../assets/image/logo_white.svg'
import bg from '../../assets/image/login/bg.png'
import Checkbox from '../UI/Checkbox/Checkbox'

const Login = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [stayLoggedIn, setStayLoggedIn] = useState<boolean>(false)
  const cookies = new Cookies()
  const navigate = useNavigate()
  const toast = useToast()

  const submit = async () => {
    const token = await auth.login(username, password)

    if (token) {
      const eightHours = 60 * 60 * 8
      const oneYear = 60 * 60 * 24 * 365
      cookies.set('token', token, { path: '/', maxAge: stayLoggedIn ? oneYear : eightHours })

      const accountLink = await auth.resolveToken(token)

      if (accountLink) {
        if (accountLink.level === Level.GOD) {
          navigate('/god')
        } else if (accountLink.level === Level.ADMIN) {
          navigate('/admin')
        } else if (accountLink.level === Level.MEMBER) {
          navigate('/member')
        }
      }
    } else {
      toast(t.login.error)
    }
  }

  return (
    <Layout authLevelRequired={false} noPadding noHeader>
      <div className="flex flex-row bg-cover bg-center" style={{ backgroundImage: `url(${bg})` }}>
        <div className="flex-1 backdrop-blur-xl">
          <Form onSubmit={submit}>
            <div className="flex flex-col gap-3 h-screen justify-center mx-auto w-6/12">
              <img src={logo} className="w-60" alt="Jade-Portal" />
              <Input className="mt-3" label placeholder={t.generic.username} onChange={setUsername} value={username} required />
              <Input label placeholder={t.generic.password} onChange={setPassword} value={password} required type="password" />
              <div className="mt-3 flex flex-row justify-between items-center gap-3">
                <Button type="submit" text={t.login.cta} />
                <Checkbox light className="pb-1" checked={stayLoggedIn} onChange={setStayLoggedIn} label={t.login.stayLoggedIn} />
              </div>
              {/* <a href="/passwort-vergessen" className="text-white w-fit">
                  {t.login.forgotPassword}
                </a> */}
            </div>
          </Form>
        </div>
        <div className="flex-1"></div>
      </div>
    </Layout>
  )
}

export default Login
