import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { SectionProps } from '../Upsert'
import DirectFileUpload, { UPLOAD_TYPES } from '../../../../UI/DirectFileUpload/DirectFileUpload'
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import { s3PublicStoragePrefix } from '../../../../../utility'
import { Logo as LogoType } from '../../../../../types/Company'
import { File } from '../../../../../types/generic'

const Logo: React.FC<SectionProps<LogoType>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof LogoType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  const setImage = (image: File) => {
    if (image) {
      internalSet('key')(image.key)
    }
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>{t.company.logo.hint}</div>
      </div>
      <div className="h-32 mt-3 rounded-lg aspect-video flex items-center justify-center overflow-hidden">
        <DirectFileUpload id={company.uuid} uploadType={UPLOAD_TYPES.COMPANY_LOGO} acceptedMimeTypes={['image/*']} onFilesCallback={setImage}>
          {!!data.key ? (
            <img src={s3PublicStoragePrefix + data.key} alt={t.company.logo.hint} className="h-full w-full object-contain" />
          ) : (
            <div className="w-full h-full self-stretch bg-blue flex items-center justify-center">
              <ArrowUpOnSquareIcon color="white" className="w-8 stroke-white hover:scale-105 transition-all" />
            </div>
          )}
        </DirectFileUpload>
      </div>
    </div>
  )
}

export default Logo
