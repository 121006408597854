import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import { s3PublicStoragePrefix } from '../../../../../../../utility'
import { Tagesordnungspunkt } from '../../../../../../../types/Versammlung/Versammlung'

const Files: React.FC<{ currentTop: Tagesordnungspunkt }> = ({ currentTop }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  if (!Array.isArray(currentTop?.files) || currentTop?.files.length === 0) return <></>
  return (
    <div>
      <div className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">{t.versammlung.moderate.detail.files}</div>
      <div className="flex flex-col">
        {currentTop.files.map((file) => (
          <a key={file.key} href={s3PublicStoragePrefix + file.key} target="_blank" rel="noreferrer" className="hover:underline">
            {file.name}
          </a>
        ))}
      </div>
    </div>
  )
}

export default Files
