import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useState } from 'react'
import Modal from '../Modal/Modal'

type Props = {
  onConfirm: () => void
  children: React.ReactNode
  text: string
}

const RemoveConfirmation: React.FC<Props> = ({ onConfirm, children, text }) => {
  const [open, setOpen] = useState<boolean>(false)
  const t = useSelector((s) => s[props.TRANSLATION])

  const toggle = () => setOpen(!open)

  const internalConfirm = () => {
    onConfirm()
    toggle()
  }

  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Modal
        noForm
        onConfirm={internalConfirm}
        show={open}
        cancelButtonText={t.generic.cancel}
        onClose={toggle}
        title={t.UI.removeConfirmation.title}
      >
        <div className="flex flex-col gap-3 mt-3">{text}</div>
      </Modal>
    </>
  )
}

export default RemoveConfirmation
