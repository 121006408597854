import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Item as ItemType } from './types'
import { Bars2Icon } from '@heroicons/react/24/outline'

type Props = {
  item: ItemType
  invert?: boolean
}

const Item: React.FC<Props> = ({ item, invert = false }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} className="touch-none flex flex-row outline-none">
      <div className="w-full">{item.content}</div>
      <div {...attributes} {...listeners} className={`w-7 h-9 flex items-center justify-center`}>
        <Bars2Icon className={`w-6 h-6 ${invert ? 'stroke-white' : 'stroke-turquoise'}`} />
      </div>
    </div>
  )
}

export default Item
