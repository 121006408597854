interface IButton {
  text?: string | React.ReactNode
  type?: string
  href?: string
  onClick?: () => void
  inverted?: boolean
  highlight?: boolean
  danger?: boolean
  className?: string
  disabled?: boolean
}

enum Type {
  DEFAULT = 'button',
  SUBMIT = 'submit',
}

const Button: React.FC<IButton> = ({
  text,
  onClick,
  href,
  inverted = false,
  highlight = false,
  danger = false,
  type = Type.DEFAULT,
  className = '',
  disabled = false,
}) => {
  const getClasses = () => {
    let classes = 'border py-1 px-3 sm:px-5 md:px-10 font-light rounded-lg w-fit block transition-all'
    if (inverted) {
      classes += ' border-white bg-white text-turquoise hover:bg-turquoise hover:text-white hover:border-white'
    } else if (highlight) {
      classes += ' border-turquoise bg-transparent text-turquoise hover:bg-turquoise hover:text-white hover:border-turquoise'
    } else if (danger) {
      classes += ' border-red bg-red text-white hover:bg-white hover:text-red'
    } else {
      classes += ' border-turquoise bg-turquoise text-white hover:bg-white hover:text-turquoise hover:border-turquoise'
    }
    return classes
  }

  if (!!href) {
    return (
      <a className={`${getClasses()} ${className}`} href={href}>
        {text}
      </a>
    )
  }
  return (
    <button
      className={`${getClasses()} ${className}`}
      type={type === Type.SUBMIT ? Type.SUBMIT : Type.DEFAULT}
      onClick={type !== Type.SUBMIT ? onClick : null}
      disabled={disabled}
    >
      {text}
    </button>
  )
}

export default Button
