import { crud } from '@think-internet/zeus-frontend-package'
import { Company } from '../../../types/Company'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Input from '../../UI/Input/Input'
import Container from '../../UI/Container/Container'
import Button from '../../UI/Button/Button'
import { BuildingOffice2Icon } from '@heroicons/react/24/outline'
import Toggle from '../../UI/Toggle/Toggle'

const List = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const companyFeature = crud.use(props.COMPANY)
  const [list, setList] = useState<Company[]>(null)
  const [search, setSearch] = useState<string>('')
  const [showInactive, setShowInactive] = useState<boolean>(false)

  const pull = useCallback(async () => {
    const result: Company[] = await companyFeature.list({})
    if (Array.isArray(result)) {
      setList(result)
      setSearch('')
    }
  }, [companyFeature])

  useEffect(() => {
    if (!list) {
      pull()
    }
  }, [list, pull])

  const filter = (company: Company) => {
    const textFilter = company.base.name.toLowerCase().includes(search.toLowerCase())
    const inactiveFilter = showInactive ? company.inaktivitaet.inaktivitaet : !company.inaktivitaet.inaktivitaet
    return textFilter && inactiveFilter
  }

  const getName = (company: Company) => `${company.base.name}`

  if (!list) return <></>
  return (
    <Container>
      <div className="py-10">
        <div className="flex gap-3 items-center justify-between">
          <div className="flex gap-3 items-center">
            <BuildingOffice2Icon className="fill-blue w-10" />
            <div className="font-bold text-lg text-blue">{t.company.list.title}</div>
          </div>
          <Button href="/admin/company/create" text={t.company.upsert.createCTA} />
        </div>
        <Input placeholder={t.company.list.search} onChange={setSearch} value={search} className="mt-3" />
        <div className="flex flex-col lg:flex-row mt-3">
          <div className="flex-1">
            <Toggle label={t.person.list.showInactive} value={showInactive} onChange={setShowInactive} />
          </div>
        </div>
        <div className="flex flex-col gap-1 mt-3">
          {list.filter(filter).map((company) => (
            <a
              href={`/admin/company/${company.uuid}`}
              key={company.uuid}
              className="flex gap-3 items-center bg-turquoise px-2 py-1 text-white rounded-lg"
            >
              {getName(company)}
            </a>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default List
