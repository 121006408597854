import { useEffect, useState } from 'react'
import { Type, Versammlung } from '../../../../../types/Versammlung/Versammlung'
import props from '../../../../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { Company } from '../../../../../types/Company'
import Loading from '../../../../UI/Loading/Loading'
import { useSelector } from 'react-redux'
import useToast, { Type as ToastType } from '../../../../hooks/useToast'
import Accordion from '../../../../UI/Accordion/Accordion'
import { getPrettyDateTime } from '../../../../../utility'
import Item from './Item'

type Props = {
  company: Company
  type: Type
}

const List: React.FC<Props> = ({ company, type }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const versammlungFeature = crud.use(props.VERSAMMLUNG)
  const [list, setList] = useState<Versammlung[]>()
  const toast = useToast()

  useEffect(() => {
    const pull = async () => {
      const list = await versammlungFeature.list({ companyUUID: company.uuid, type })
      if (Array.isArray(list)) {
        setList(list)
      } else {
        toast(t.versammlung.list.error.loading, ToastType.ERROR)
      }
    }
    if (!list) pull()
  })

  const getItems = (past = false) =>
    list
      .filter((v) => (past ? new Date(v.datumUhrzeit) < new Date() : new Date(v.datumUhrzeit) > new Date()))
      .map((v, i) => ({
        title: getPrettyDateTime(v.datumUhrzeit),
        content: <Item company={company} type={type} versammlung={v} />,
        id: i,
      }))

  if (!list) {
    return (
      <div className="flex flex-col items-center">
        <Loading loading={false} />
        <div className="text-sm">{t.versammlung.list.loading}</div>
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-3">
      {getItems().length > 0 && (
        <div>
          <div className="font-bold">{t.versammlung.list.upcoming}</div>
          <Accordion items={getItems()} />
        </div>
      )}
      {getItems(true).length > 0 && (
        <div>
          <div className="font-bold">{t.versammlung.list.past}</div>
          <Accordion items={getItems(true)} />
        </div>
      )}
    </div>
  )
}

export default List
