import Detail from './Detail/Detail'
import VotingList from './VotingList/VotingList'

const TOP: React.FC<{}> = () => {
  return (
    <div className="flex flex-row gap-10 mt-3">
      <div className="w-2/3 shrink-0">
        <Detail />
      </div>
      <div className="w-1/3 shrink-0">
        <VotingList />
      </div>
    </div>
  )
}

export default TOP
