import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { InputWrapper, SectionProps } from '../Upsert'
import Toggle from '../../../../UI/Toggle/Toggle'
import { Inaktivitaet as InaktivitaetType } from '../../../../../types/Company'

const Inaktivitaet: React.FC<SectionProps<InaktivitaetType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof InaktivitaetType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <InputWrapper itemsPerRow={3}>
        <Toggle label={t.attributes.inaktivitaet} value={data['inaktivitaet']} onChange={internalSet('inaktivitaet')} />
      </InputWrapper>
    </div>
  )
}

export default Inaktivitaet
