import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'

type Props = {
  name: string
  url: string
  children?: React.ReactNode
}

const DownloadByLink: React.FC<Props> = ({ name, url, children }) => {
  return (
    <a href={url} download={name} target="_blank" rel="noreferrer">
      {children ? children : <ArrowDownTrayIcon className="w-6 stroke-blue cursor-pointer" />}
    </a>
  )
}

export default DownloadByLink
