import Input from '../../../../UI/Input/Input'
import Country from '../../../../UI/Input/Country'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { InputWrapper, SectionProps } from '../Upsert'
import Onliner from '../../../../UI/Input/Onliner'
import CompanyLegalTypes from '../../../../UI/Input/CompanyLegalType'
import Toggle from '../../../../UI/Toggle/Toggle'
import { Base as BaseType } from '../../../../../types/Company'

const Base: React.FC<SectionProps<BaseType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof BaseType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <InputWrapper>
        <Input label placeholder={t.attributes.name} value={data['name']} onChange={internalSet('name')} />
      </InputWrapper>
      <InputWrapper>
        <CompanyLegalTypes value={data['rechtsform']} onChange={internalSet('rechtsform')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.strasseHausnummer} value={data['strasseHausnummer']} onChange={internalSet('strasseHausnummer')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.postleitzahl} value={data['postleitzahl']} onChange={internalSet('postleitzahl')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.stadt} value={data['stadt']} onChange={internalSet('stadt')} />
      </InputWrapper>
      <InputWrapper>
        <Country value={data['land']} onChange={internalSet('land')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.telefon} value={data['telefon']} onChange={internalSet('telefon')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.mobilTelefon} value={data['mobilTelefon']} onChange={internalSet('mobilTelefon')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.mail} value={data['mail']} onChange={internalSet('mail')} />
      </InputWrapper>
      <InputWrapper>
        <Onliner value={data['onliner']} onChange={internalSet('onliner')} />
      </InputWrapper>
      <div>
        <Toggle label={t.attributes.externallyManaged} value={data['externallyManaged']} onChange={internalSet('externallyManaged')} />
      </div>
    </div>
  )
}

export default Base
