import { useEffect, useState } from 'react'
import props from '../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { Beteiligung } from '../../types/Beteiligung'

type Query = Omit<Beteiligung, 'anfangszeitpunkt'> & { anfangszeitpunkt?: { $lte: Date } }

const useGetBeteiligung = (query: Query): Beteiligung | false => {
  const [beteiligung, setBeteiligung] = useState<Beteiligung | false>(null)
  const beteiligungFeature = crud.use(props.BETEILIGUNG)

  useEffect(() => {
    const pull = async () => {
      const beteiligung = await beteiligungFeature.get(query)
      if (beteiligung) {
        setBeteiligung(beteiligung)
      } else {
        setBeteiligung(false)
      }
    }
    if (beteiligung === null) {
      pull()
    }
  }, [query, beteiligung, beteiligungFeature])

  return beteiligung
}

export default useGetBeteiligung
