import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import props from '../../../redux/props'
import { Enthaltung as EnthaltungEnum } from '../../../types/Mehrheit'

const Enthaltung = ({ title = null, value, onChange, required = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Dropdown
      label={title || t.UI.enthaltung.title}
      required={required}
      items={Object.keys(EnthaltungEnum).map((e) => ({ label: t.UI.enthaltung[e], value: e }))}
      value={value}
      onChange={onChange}
    />
  )
}

export default Enthaltung
