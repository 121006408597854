import { useDispatch, useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'
import { ModerationState, moderationStateProp } from '../../../../../../types/Versammlung/Moderation'
import { setLocal } from '../../../../../../redux/action/local'

type Finalize = () => Promise<boolean>

type Return = Finalize

const useFinalize = (): Return => {
  const moderationState: ModerationState = useSelector((s) => s[moderationStateProp])
  const submit = functional.use(Routes.VERSAMMLUNG_FINALIZE)
  const dispatch = useDispatch()

  const finalize: Finalize = async () => {
    if (!moderationState) return false
    const versammlungErgebnis = await submit({ versammlungErgebnis: moderationState.versammlungErgebnis })
    if (!versammlungErgebnis) return false
    dispatch(setLocal(moderationStateProp, { ...moderationState, versammlungErgebnis }))
    return true
  }

  return finalize
}

export default useFinalize
