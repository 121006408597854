import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import Button from '../../../../../UI/Button/Button'
import Modal from '../../../../../UI/Modal/Modal'
import { SectionProps } from '../../Upsert'
import PersonSelect from '../../../../../UI/PersonSelect/PersonSelect'
import { Person } from '../../../../../../types/Person'
import { Administratoren } from '../../../../../../types/Company'

const Add: React.FC<{ type: string } & SectionProps<Administratoren['primaerResolved'] | Administratoren['sekundaerResolved']>> = ({
  type,
  data,
  set,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)

  const [personList, setPersonList] = useState<Person[]>(data || [])

  const toggle = () => setOpen(!open)

  const confirm = async () => {
    set(personList)
    toggle()
  }

  return (
    <>
      <Button onClick={toggle} text={t.company.administratoren.add.cta} />
      <Modal noForm large onConfirm={confirm} show={open} onClose={toggle} title={t.company.administratoren.add[type].title}>
        <PersonSelect single={type === 'primaer'} selected={personList} onSelect={setPersonList} />
      </Modal>
    </>
  )
}

export default Add
