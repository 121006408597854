import { useCallback, useState } from 'react'

type Return = {
  init: (data: any) => void
  hasChanged: boolean
}

const dataCleanup = (data: any) => {
  const cleanedData = Object.keys(data).reduce((acc, key) => {
    if ((typeof data[key] === 'string' && data[key].trim() !== '') || typeof data[key] === 'number' || typeof data[key] === 'boolean') {
      acc[key] = data[key]
    }
    return acc
  }, {})
  return cleanedData
}

const useHasChanged = (data: any): Return => {
  const [initData, setInitData] = useState(data)

  const init = (data: any) => setInitData(data)

  const hasChanged = useCallback(() => {
    if (!initData) return false

    return JSON.stringify(dataCleanup(initData)) !== JSON.stringify(dataCleanup(data))
  }, [initData, data])

  return { init, hasChanged: hasChanged() }
}

export default useHasChanged
