import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import Button from '../../../../../../UI/Button/Button'
import { useEffect } from 'react'
import Input from '../../../../../../UI/Input/Input'
import { TrashIcon } from '@heroicons/react/24/outline'
import { getUUID } from '../../../../../../../utility'
import { VotingOption } from '../../../../../../../types/Versammlung/Versammlung'

type Props = {
  options: VotingOption[]
  set: (options: VotingOption[]) => void
}

const VotingOptions: React.FC<Props> = ({ options, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    if (!Array.isArray(options)) {
      set([])
    }
  }, [options, set])

  const add = () => set([...options, { name: '', uuid: getUUID() }])

  const updateOption = (uuid: string) => (value: string) => {
    const newOptions = options.map((o, i) => (o.uuid === uuid ? { ...o, name: value } : o))
    set(newOptions)
  }

  const remove = (uuid: string) => () => {
    set(options.filter((o) => o.uuid !== uuid))
  }

  if (!Array.isArray(options)) return null
  return (
    <div>
      <div className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">{t.versammlung.attributes.votingOptions}</div>
      {options.map((o, i) => (
        <div className="flex flex-row gap-2 items-center mt-1" key={o.uuid}>
          <Input value={o.name} onChange={updateOption(o.uuid)} />
          <div onClick={remove(o.uuid)}>
            <TrashIcon className="w-6 stroke-red cursor-pointer" />
          </div>
        </div>
      ))}
      <Button className="ml-auto mt-1" text={t.versammlung.upsert.votingOptions.addCTA} onClick={add} />
    </div>
  )
}

export default VotingOptions
