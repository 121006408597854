import props from '../../../../../../redux/props'
import { useSelector } from 'react-redux'
import { SectionProps } from '../../Upsert'
import { Administratoren as AdministratorenType } from '../../../../../../types/Company'
import Add from './Add'
import { Person } from '../../../../../../types/Person'
import PersonList from '../../../../../UI/PersonList/PersonList'
import Section from '../../../../../UI/Accordion/Section/Section'

const Administratoren: React.FC<SectionProps<AdministratorenType>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const setAdminList = (prop: keyof AdministratorenType) => (admins: Person[]) => {
    const uuidList = admins.map((person) => person.uuid)
    if (prop === 'primaer') {
      set({ ...data, primaer: uuidList, primaerResolved: admins })
    } else if (prop === 'sekundaer') {
      set({ ...data, sekundaer: uuidList, sekundaerResolved: admins })
    }
  }

  return (
    <div className="flex flex-col gap-3">
      <Section title={t.company.administratoren.list.primaer} light>
        <PersonList list={data.primaerResolved} />
        <div className="flex justify-end mt-1">
          <Add type={'primaer'} data={data.primaerResolved} set={setAdminList('primaer')} />
        </div>
      </Section>
      <Section title={t.company.administratoren.list.sekundaer} light>
        <PersonList list={data.sekundaerResolved} />
        <div className="flex justify-end mt-1">
          <Add type={'sekundaer'} data={data.sekundaerResolved} set={setAdminList('sekundaer')} />
        </div>
      </Section>
    </div>
  )
}

export default Administratoren
