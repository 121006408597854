import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { File } from '../../../types/generic'
import DirectFileUpload, { UPLOAD_TYPES } from '../DirectFileUpload/DirectFileUpload'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { s3PublicStoragePrefix } from '../../../utility'
import { useState } from 'react'
import Progress from '../Progress/Progress'
import DownloadByLink from '../DownloadByLink'

type Props = {
  id: string
  files: File[]
  onFiles: (files: File[]) => void
  uploadType: UPLOAD_TYPES
  removeCallback: (file: File) => () => void
}

const AddFiles: React.FC<Props> = ({ id, files, onFiles, removeCallback, uploadType }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [progress, setProgress] = useState<number>(0)

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between items-center">
        <div className="text-blue font-bold">{t.UI.addFiles.title}</div>
        <DirectFileUpload id={id} progressCallback={setProgress} multiple className="!w-fit" uploadType={uploadType} onFilesCallback={onFiles}>
          <div className="text-blue flex flex-row gap-1 bg-turquoise py-1 px-2 rounded-lg">
            <PlusCircleIcon className="w-5 stroke-white" />
            <div className="text-white">{t.UI.addFiles.cta}</div>
          </div>
        </DirectFileUpload>
      </div>
      <Progress progress={progress} />
      <div className="flex flex-col gap-1">
        {Array.isArray(files) &&
          files.length > 0 &&
          files.map((file, i) => (
            <div key={i} className="flex flex-row justify-between items-center">
              <div className="truncate">{file.name}</div>
              <div className="flex flex-row gap-5">
                <DownloadByLink url={s3PublicStoragePrefix + file.key} name={file.name} />
                <div onClick={removeCallback(file)}>
                  <TrashIcon className="w-6 stroke-red cursor-pointer" />
                </div>
              </div>
            </div>
          ))}
        {(!Array.isArray(files) || files.length === 0) && <div className="text-sm text-center">{t.UI.addFiles.empty}</div>}
      </div>
    </div>
  )
}

export default AddFiles
