import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { InputWrapper, SectionProps } from '../Upsert'
import Currency from '../../../../UI/Input/Currency'
import Number from '../../../../UI/Input/Number'
import { Kapitalisierung as KapitalisierungType } from '../../../../../types/Company'

const Kapitalisierung: React.FC<SectionProps<KapitalisierungType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof KapitalisierungType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <InputWrapper>
        <Currency label placeholder={t.attributes.initialKapital} value={data['initialKapital']} onChange={internalSet('initialKapital')} />
      </InputWrapper>
      <InputWrapper>
        <Number label placeholder={t.attributes.stimmenGesamt} value={data['stimmenGesamt']} onChange={internalSet('stimmenGesamt')} />
      </InputWrapper>
    </div>
  )
}

export default Kapitalisierung
