import Input from '../../../../UI/Input/Input'
import Country from '../../../../UI/Input/Country'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { InputWrapper, SectionProps } from '../Upsert'
import Onliner from '../../../../UI/Input/Onliner'
import { Kontakt as KontaktType, Titel, Ansprache } from '../../../../../types/Person'
import Dropdown from '../../../../UI/Dropdown/Dropdown'

const ContactInformation: React.FC<SectionProps<KontaktType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof KontaktType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <InputWrapper>
        <Dropdown
          label={t.attributes.titel}
          required
          value={data['titel']}
          items={Object.keys(Titel).map((titel) => ({ label: t.attributes.titelItems[titel], value: titel }))}
          onChange={internalSet('titel')}
        />
      </InputWrapper>
      <InputWrapper>
        <Dropdown
          label={t.attributes.ansprache}
          required
          value={data['ansprache']}
          items={Object.keys(Ansprache).map((s) => ({ label: t.attributes.anspracheItems[s], value: s }))}
          onChange={internalSet('ansprache')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.vorname} value={data['vorname']} onChange={internalSet('vorname')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.nachname} value={data['nachname']} onChange={internalSet('nachname')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.geburtsname} value={data['geburtsname']} onChange={internalSet('geburtsname')} />
      </InputWrapper>
      <InputWrapper>
        <Input type="date" label placeholder={t.attributes.geburtsdatum} value={data['geburtsdatum']} onChange={internalSet('geburtsdatum')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.strasseHausnummer} value={data['strasseHausnummer']} onChange={internalSet('strasseHausnummer')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.postleitzahl} value={data['postleitzahl']} onChange={internalSet('postleitzahl')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.stadt} value={data['stadt']} onChange={internalSet('stadt')} />
      </InputWrapper>
      <InputWrapper>
        <Country value={data['land']} onChange={internalSet('land')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.telefon} value={data['telefon']} onChange={internalSet('telefon')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.mobilTelefon} value={data['mobilTelefon']} onChange={internalSet('mobilTelefon')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.mail} value={data['mail']} onChange={internalSet('mail')} />
      </InputWrapper>
      <InputWrapper>
        <Onliner value={data['onliner']} onChange={internalSet('onliner')} />
      </InputWrapper>
      {/* <InputWrapper>Steuerberater</InputWrapper>
      <InputWrapper>Vormund</InputWrapper> */}
    </div>
  )
}

export default ContactInformation
