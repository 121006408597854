import { useState } from 'react'
import Section from './Section/Section'
import { StatusObject } from './Section/StatusBell'
import DNDList from '../DNDList/DNDList'

export type SectionItem = {
  title: React.ReactNode | string
  content: React.ReactNode | string
  data?: Object
  status?: StatusObject | boolean
} & {
  id: string | number
}

type Props = {
  items: SectionItem[]
  className?: string
  initIndex?: number
  secondary?: boolean
  draggableCallback?: (items: SectionItem[]) => void
  indexHandler?: [number, React.Dispatch<React.SetStateAction<number>>]
}

const Accordion: React.FC<Props> = ({ items, initIndex = null, indexHandler = null, secondary, draggableCallback = null, className = '' }) => {
  const [openIndex, setOpenIndex] = useState<number>(initIndex)
  const [externalIndex, setExternalIndex] = indexHandler || [null, () => {}]

  const toggle = (index: number) => () => {
    if (indexHandler) {
      setExternalIndex(externalIndex === index ? null : index)
    } else {
      setOpenIndex(openIndex === index ? null : index)
    }
  }

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {draggableCallback ? (
        <DNDList
          orderUpdateCallback={draggableCallback}
          items={items.map((item, i) => {
            return {
              id: item.id,
              data: item.data,
              content: (
                <Section
                  key={item.id}
                  secondary={secondary}
                  open={indexHandler ? externalIndex === i : openIndex === i}
                  title={item.title}
                  clickedCallback={toggle(i)}
                  status={item.status}
                  className="my-1"
                >
                  {item.content}
                </Section>
              ),
            }
          })}
        />
      ) : (
        items.map(({ title, content, status, id }, i) => (
          <Section
            key={id}
            secondary={secondary}
            open={indexHandler ? externalIndex === i : openIndex === i}
            title={title}
            clickedCallback={toggle(i)}
            status={status}
          >
            {content}
          </Section>
        ))
      )}
    </div>
  )
}

export default Accordion
