type Props = {
  options: { name: string; value: any }[]
  selected?: any
  onSelect: (value: any) => void
  className?: string
}

const OptionSelect: React.FC<Props> = ({ options, className, selected, onSelect }) => {
  return (
    <div className={`flex flex-row rounded-lg overflow-hidden ${className}`}>
      {options.map((option, index) => (
        <div
          key={index}
          className={`flex-1 py-1 px-2 cursor-pointer text-white text-center ${index > 0 && 'border-l border-white'} ${
            selected === option.value ? 'bg-blue font-bold' : 'bg-turquoise'
          }`}
          onClick={() => onSelect(option.value)}
        >
          {option.name}
        </div>
      ))}
    </div>
  )
}

export default OptionSelect
