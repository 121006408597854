import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import Todo from './Todo/Todo'
import Company from './Company/Company'
import Person from './Person/Person'

const Admin = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN]} noFooter>
      <Routes>
        <Route path={''} element={<Todo />} />
        <Route path={'company/*'} element={<Company />} />
        <Route path={'person/*'} element={<Person />} />
        <Route path={'*'} element={<Navigate replace to="/admin" />} />
      </Routes>
    </Layout>
  )
}

export default Admin
