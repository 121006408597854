import Input from '../../../../UI/Input/Input'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { InputWrapper, SectionProps } from '../Upsert'
import Level from '../../../../UI/Input/Level'
import { Login as LoginType } from '../../../../../types/Person'

const LoginInformation: React.FC<SectionProps<LoginType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof LoginType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <InputWrapper itemsPerRow={3}>
        <Input label placeholder={t.attributes.username} value={data['identifier']} onChange={internalSet('identifier')} />
      </InputWrapper>
      <InputWrapper itemsPerRow={3}>
        <Input label placeholder={t.attributes.password} value={data['password']} onChange={internalSet('password')} />
      </InputWrapper>
      <InputWrapper itemsPerRow={3}>
        <Level value={data['level']} onChange={internalSet('level')} />
      </InputWrapper>
    </div>
  )
}

export default LoginInformation
