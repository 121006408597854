import React from 'react'
import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core'
import Column from './Column'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'

export type DNDItem = {
  id: string | number
  content: React.ReactNode
  data: any
}

type Props = { items: DNDItem[]; orderUpdateCallback: (items: any[]) => void; invert?: boolean }

const DNDList: React.FC<Props> = ({ items, orderUpdateCallback, invert = false }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const dragEnd = (event: any) => {
    const { active, over } = event

    if (active.id === over.id) return

    const oldIndex = items.findIndex((i) => i.id === active.id)
    const newIndex = items.findIndex((i) => i.id === over.id)
    const orderedItems = arrayMove(items, oldIndex, newIndex)
    orderUpdateCallback(orderedItems.map((i) => i.data))
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={dragEnd} sensors={sensors}>
      <Column items={items} invert={invert} />
    </DndContext>
  )
}

export default DNDList
