import React from 'react'
import { ArrowRightEndOnRectangleIcon, FlagIcon } from '@heroicons/react/24/outline'
import Button from '../../../../../UI/Button/Button'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import useFinalize from '../hooks/useFinalize'
import useSaveEndDate from '../hooks/useSaveEndDate'
import useModerationState from '../hooks/useModerationState'
import { getPrettyDateTime } from '../../../../../../utility'
import _ from 'lodash'

const End: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const saveEndDate = useSaveEndDate()
  const finalize = useFinalize()
  const moderationState = useModerationState()

  const hasEndTime = () => {
    if (!moderationState) return false
    return !!moderationState.versammlungErgebnis.endDate
  }

  return (
    <div className="flex flex-row gap-10 mt-10">
      <div className="flex-1 flex flex-col justify-center items-center gap-5">
        <div className="bg-turquoise rounded-full w-40 aspect-square flex items-center justify-center">
          <ArrowRightEndOnRectangleIcon
            className={`stroke-white w-28 ${!hasEndTime() && 'cursor-pointer'}`}
            onClick={hasEndTime() ? null : saveEndDate}
          />
        </div>
        {!hasEndTime() && <Button onClick={saveEndDate} text={t.versammlung.moderate.end.saveDate.cta} />}
        {moderationState && _.has(moderationState, 'versammlungErgebnis.endDate') && (
          <div>
            {t.versammlung.moderate.end.saveDate.savedPrefix}
            <span className="font-bold">{getPrettyDateTime(moderationState.versammlungErgebnis.endDate)}</span>
          </div>
        )}
        <div className="text-sm text-center mx-auto w-2/3">{t.versammlung.moderate.end.saveDate.hint}</div>
      </div>
      <div className="flex-1 flex flex-col justify-center items-center gap-5">
        <div className="bg-blue rounded-full w-52 aspect-square flex items-center justify-center">
          <FlagIcon className="stroke-white w-28 cursor-pointer" onClick={finalize} />
        </div>
        <Button onClick={finalize} text={t.versammlung.moderate.end.finalize.cta} />
        <div className="text-sm text-center mx-auto w-2/3">{t.versammlung.moderate.end.finalize.hint}</div>
      </div>
    </div>
  )
}

export default End
