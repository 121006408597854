import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import props from '../../../redux/props'

const Country = ({ value, onChange, required = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Dropdown
      label={t.UI.country.title}
      required={required}
      items={Object.keys(countries).map((c) => ({ label: countries[c], value: c }))}
      value={value}
      onChange={onChange}
    />
  )
}

export default Country

const countries = {
  DE: 'Deutschland',
  AF: 'Afghanistan',
  EG: 'Ägypten',
  AL: 'Albanien',
  DZ: 'Algerien',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarktis',
  AG: 'Antigua und Barbuda',
  GQ: 'Äquatorial Guinea',
  AR: 'Argentinien',
  AM: 'Armenien',
  AW: 'Aruba',
  AZ: 'Aserbaidschan',
  ET: 'Äthiopien',
  AU: 'Australien',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BE: 'Belgien',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermudas',
  BT: 'Bhutan',
  MM: 'Birma',
  BO: 'Bolivien',
  BA: 'Bosnien-Herzegowina',
  BW: 'Botswana',
  BV: 'Bouvet Inseln',
  BR: 'Brasilien',
  IO: 'Britisch-Indischer Ozean',
  BN: 'Brunei',
  BG: 'Bulgarien',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CK: 'Cook Inseln',
  CR: 'Costa Rica',
  DK: 'Dänemark',
  DJ: 'Djibuti',
  DM: 'Dominika',
  DO: 'Dominikanische Republik',
  EC: 'Ecuador',
  SV: 'El Salvador',
  CI: 'Elfenbeinküste',
  ER: 'Eritrea',
  EE: 'Estland',
  FK: 'Falkland Inseln',
  FO: 'Färöer Inseln',
  FJ: 'Fidschi',
  FI: 'Finnland',
  FR: 'Frankreich',
  GF: 'französisch Guyana',
  PF: 'Französisch Polynesien',
  TF: 'Französisches Süd-Territorium',
  GA: 'Gabun',
  GM: 'Gambia',
  GE: 'Georgien',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GD: 'Grenada',
  GR: 'Griechenland',
  GL: 'GrÃ¶nland',
  UK: 'GroÃŸbritannien',
  GB: 'GroÃŸbritannien (UK)',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GN: 'Guinea',
  GW: 'Guinea Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard und McDonald Islands',
  HN: 'Honduras',
  HK: 'Hong Kong',
  IN: 'Indien',
  ID: 'Indonesien',
  IQ: 'Irak',
  IR: 'Iran',
  IE: 'Irland',
  IS: 'Island',
  IL: 'Israel',
  IT: 'Italien',
  JM: 'Jamaika',
  JP: 'Japan',
  YE: 'Jemen',
  JO: 'Jordanien',
  YU: 'Jugoslawien',
  KY: 'Kaiman Inseln',
  KH: 'Kambodscha',
  CM: 'Kamerun',
  CA: 'Kanada',
  CV: 'Kap Verde',
  KZ: 'Kasachstan',
  KE: 'Kenia',
  KG: 'Kirgisistan',
  KI: 'Kiribati',
  CC: 'Kokosinseln',
  CO: 'Kolumbien',
  KM: 'Komoren',
  CG: 'Kongo',
  CD: 'Kongo, Demokratische Republik',
  HR: 'Kroatien',
  CU: 'Kuba',
  KW: 'Kuwait',
  LA: 'Laos',
  LS: 'Lesotho',
  LV: 'Lettland',
  LB: 'Libanon',
  LR: 'Liberia',
  LY: 'Libyen',
  LI: 'Liechtenstein',
  LT: 'Litauen',
  LU: 'Luxemburg',
  MO: 'Macao',
  MG: 'Madagaskar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Malediven',
  ML: 'Mali',
  MT: 'Malta',
  MP: 'Marianen',
  MA: 'Marokko',
  MH: 'Marshall Inseln',
  MQ: 'Martinique',
  MR: 'Mauretanien',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MK: 'Mazedonien',
  MX: 'Mexiko',
  FM: 'Mikronesien',
  MZ: 'Mocambique',
  MD: 'Moldavien',
  MC: 'Monaco',
  MN: 'Mongolei',
  MS: 'Montserrat',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NC: 'Neukaledonien',
  NZ: 'Neuseeland',
  NI: 'Nicaragua',
  NL: 'Niederlande',
  AN: 'Niederländische Antillen',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  KP: 'Nord Korea',
  NF: 'Norfolk Inseln',
  NO: 'Norwegen',
  OM: 'Oman',
  AT: 'Österreich',
  PK: 'Pakistan',
  PS: 'Palästina',
  PW: 'Palau',
  PA: 'Panama',
  PG: 'Papua Neuguinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippinen',
  PN: 'Pitcairn',
  PL: 'Polen',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RW: 'Ruanda',
  RO: 'Rumänien',
  RU: 'Russland',
  LC: 'Saint Lucia',
  ZM: 'Sambia',
  AS: 'Samoa',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome',
  SA: 'Saudi Arabien',
  SE: 'Schweden',
  CH: 'Schweiz',
  SN: 'Senegal',
  SC: 'Seychellen',
  SL: 'Sierra Leone',
  SG: 'Singapur',
  SK: 'Slowakei -slowakische Republik-',
  SI: 'Slowenien',
  SB: 'Solomon Inseln',
  SO: 'Somalia',
  GS: 'South Georgia, South Sandwich Isl.',
  ES: 'Spanien',
  LK: 'Sri Lanka',
  SH: 'St. Helena',
  KN: 'St. Kitts Nevis Anguilla',
  PM: 'St. Pierre und Miquelon',
  VC: 'St. Vincent',
  KR: 'Süd Korea',
  ZA: 'Südafrika',
  SD: 'Sudan',
  SR: 'Surinam',
  SJ: 'Svalbard und Jan Mayen Islands',
  SZ: 'Swasiland',
  SY: 'Syrien',
  TJ: 'Tadschikistan',
  TW: 'Taiwan',
  TZ: 'Tansania',
  TH: 'Thailand',
  TP: 'Timor',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad Tobago',
  TD: 'Tschad',
  CZ: 'Tschechische Republik',
  TN: 'Tunesien',
  TR: 'Türkei',
  TM: 'Turkmenistan',
  TC: 'Turks und Kaikos Inseln',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  HU: 'Ungarn',
  UY: 'Uruguay',
  UZ: 'Usbekistan',
  VU: 'Vanuatu',
  VA: 'Vatikan',
  VE: 'Venezuela',
  AE: 'Vereinigte Arabische Emirate',
  US: 'Vereinigte Staaten von Amerika',
  VN: 'Vietnam',
  VG: 'Virgin Island (Brit.)',
  VI: 'Virgin Island (USA)',
  WF: 'Wallis et Futuna',
  BY: 'Weissrussland',
  EH: 'Westsahara',
  CF: 'Zentralafrikanische Republik',
  ZW: 'Zimbabwe',
  CY: 'Zypern',
}
