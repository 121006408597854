import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'

type GetProtocol = () => Promise<void>

type Return = GetProtocol

const useGetProtocol = (uuid: string): Return => {
  const submit = functional.use(Routes.VERSAMMLUNG_GET_PROTOCOL)

  const getProtocol: GetProtocol = async () => {
    await submit({ uuid }, null, null, null, null, 'application/pdf')
  }

  return getProtocol
}

export default useGetProtocol
