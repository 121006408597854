import { useState } from 'react'
import Items from '../Items/Items'
import { Bars3Icon } from '@heroicons/react/24/solid'

const Toggle: React.FC<{}> = () => {
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <>
      <div className="cursor-pointer" onClick={toggle}>
        <div className="w-10 h-10">
          <Bars3Icon className="fill-white" />
        </div>
      </div>
      <div className={`${open ? 'block' : 'hidden'} fixed w-full px-3 top-16 left-0`}>
        <div className={`bg-green-primary p-5 rounded-xl bg-white backdrop-blur-sm shadow-sm`}>
          <div className="flex flex-col gap-2">
            <Items mobile onClick={toggle} />
          </div>
        </div>
      </div>
    </>
  )
}
export default Toggle
