import VotingList from './VotingList/VotingList'
import Beschlussfaehigkeit from './Beschlussfaehigkeit/Beschlussfaehigkeit'
import Next from './Next/Next'

const Start: React.FC<{}> = () => {
  return (
    <div className="flex flex-row gap-10 mt-3">
      <div className="flex-1 shrink-0">
        <VotingList anwesendOnly />
      </div>
      <div className="flex-1 shrink-0">
        <Beschlussfaehigkeit topIndex={0} />
      </div>
      <div className="flex-1 shrink-0">
        <Next />
      </div>
    </div>
  )
}

export default Start
