import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../redux/props'
import { Uebertragung } from '../../../types/Uebertragung'
import { useEffect, useState } from 'react'
import useToast, { Type } from '../../hooks/useToast'
import { useSelector } from 'react-redux'
import List from './List'
import Add from './Add'

type CompanyProps = {
  companyUUID: string
  personUUID?: never
}
type PersonProps = {
  personUUID: string
  companyUUID?: never
}

const UebertragungManager: React.FC<CompanyProps | PersonProps> = ({ personUUID, companyUUID }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const uebertragungFeature = crud.use(props.UEBERTRAGUNG)
  const [uebertragungen, setUebertragungen] = useState<Uebertragung[]>()
  const toast = useToast()

  useEffect(() => {
    const pull = async () => {
      const query = personUUID ? { personUUID } : { companyUUID }
      const uebertragungen = await uebertragungFeature.list(query)
      if (Array.isArray(uebertragungen)) {
        setUebertragungen(uebertragungen)
      } else {
        toast(t.UI.uebertragungManager.error.list, Type.ERROR)
      }
    }
    if (!Array.isArray(uebertragungen)) pull()
  }, [personUUID, companyUUID, uebertragungFeature, uebertragungen, toast, t])

  const add = (uebertragung: Uebertragung) => {
    setUebertragungen((prev) => [...prev, uebertragung])
  }

  if (!Array.isArray(uebertragungen)) return <></>
  return (
    <div>
      <List uebertragungen={uebertragungen} />
      <div className="flex justify-end mt-3">
        <Add personUUID={personUUID} companyUUID={companyUUID} callback={add} />
      </div>
    </div>
  )
}

export default UebertragungManager
