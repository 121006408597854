import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../../../../redux/action/local'
import useModerationState from './useModerationState'
import { ActiveTOPEnum, ActiveTOPState, ModerationState, moderationStateProp } from '../../../../../../types/Versammlung/Moderation'
import useSave from './useSave'
import useToast from '../../../../../hooks/useToast'
import props from '../../../../../../redux/props'

type Return = {
  hasNext: () => ActiveTOPState | false
  next: (startDate?: Date) => void
  hasPrevious: () => number | ActiveTOPState | false
  previous: () => void
}

const useNavigation = (): Return => {
  const dispatch = useDispatch()
  const moderationState = useModerationState()
  const save = useSave()
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  if (!moderationState) return { hasNext: () => false, next: () => {}, hasPrevious: () => false, previous: () => {} }
  const { activeTOP, versammlung, versammlungErgebnis } = moderationState

  const setActiveTOP = async (top: ActiveTOPState, overwriteModerationState?: ModerationState) => {
    const newState: ModerationState = { ...(overwriteModerationState || moderationState), activeTOP: top }
    if (!moderationState.isDemoMode) {
      const savedState = await save(newState)
      if (!!savedState) {
        dispatch(setLocal(moderationStateProp, { ...moderationState, ...savedState }))
      } else {
        toast(t.versammlung.moderate.navigate.error.save)
      }
    } else {
      dispatch(setLocal(moderationStateProp, newState))
    }
  }

  const hasNext = (): ActiveTOPState | false => {
    if (activeTOP === ActiveTOPEnum.START) {
      return 0
    } else if (typeof activeTOP === 'number') {
      if (activeTOP + 1 < versammlung.tagesordnungspunkte.length) {
        return activeTOP + 1
      } else {
        return ActiveTOPEnum.END
      }
    } else {
      return false
    }
  }

  const next = (startDate?: Date) => {
    const next = hasNext()
    if (next !== false && next !== ActiveTOPEnum.END) {
      const currentIndex = typeof activeTOP === 'number' ? activeTOP : 0
      const nextVersammlungTOP = versammlung.tagesordnungspunkte[next]
      const currentErgebnisTOP = versammlungErgebnis.tagesordnungspunkte[currentIndex]
      const nextErgebnisTOP = versammlungErgebnis.tagesordnungspunkte[next]

      if (!nextErgebnisTOP) {
        const newNextErgebnisTOP = {
          key: nextVersammlungTOP.key,
          ergebnisse: currentErgebnisTOP.ergebnisse.map((e) => ({
            beteiligungUUID: e.beteiligungUUID,
            anwesend: e.anwesend,
          })),
          protokoll: '',
        }
        const newErgebnisTagesordnungspunkte = versammlungErgebnis.tagesordnungspunkte.concat(newNextErgebnisTOP)
        const newState: ModerationState = {
          ...moderationState,
          versammlungErgebnis: {
            ...versammlungErgebnis,
            tagesordnungspunkte: newErgebnisTagesordnungspunkte,
          },
          activeTOP: next,
        }
        if (!newState.versammlungErgebnis.startDate && startDate && activeTOP === ActiveTOPEnum.START) {
          newState.versammlungErgebnis.startDate = startDate
          newState.isDemoMode = false
        }
        setActiveTOP(next, newState)
      } else if (startDate) {
        const newState: ModerationState = {
          ...moderationState,
          activeTOP: next,
          isDemoMode: false,
        }
        if (!newState.versammlungErgebnis.startDate && startDate && activeTOP === ActiveTOPEnum.START) {
          newState.versammlungErgebnis.startDate = startDate
        }
        setActiveTOP(next, newState)
      } else {
        setActiveTOP(next)
      }
    } else if (next === ActiveTOPEnum.END) {
      setActiveTOP(next)
    }
  }

  const hasPrevious = (): number | ActiveTOPState | false => {
    if (activeTOP === ActiveTOPEnum.START) return false
    if (activeTOP === ActiveTOPEnum.END) return versammlung.tagesordnungspunkte.length - 1
    if (typeof activeTOP === 'number') {
      if (activeTOP === 0) return ActiveTOPEnum.START
      return activeTOP - 1
    }
    return ActiveTOPEnum.START
  }

  const previous = () => {
    const previous = hasPrevious()
    if (previous !== false) {
      setActiveTOP(previous)
    }
  }

  return {
    hasNext,
    next,
    hasPrevious,
    previous,
  }
}

export default useNavigation
