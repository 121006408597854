import useModerationState from './useModerationState'
import { useEffect, useState } from 'react'
import {
  Abstimmung,
  AbstimmungDefault,
  Tagesordnungspunkt as TagesordnungspunktErgebnis,
} from '../../../../../../types/Versammlung/VersammlungErgebnis'
import useGetCurrentTOP from './useGetCurrentTOP'
import { AbsoluteState, Enthaltung, Mehrheit } from '../../../../../../types/Mehrheit'
import { Tagesordnungspunkt, TagesordnungspunktType } from '../../../../../../types/Versammlung/Versammlung'

type StimmenAndKapital = {
  stimmen: number
  kapital: number
}

type State = {
  zustimmung: boolean
  minStimmen: number
  totalStimmen: number
  totalBeteiligungAmount: number
  totalVotedBeteiligungAmount: number
  currentZustimmungStimmen: number
  currentZustimmungPercent: number
}

export type AbstimmungResult = {
  state: State
  mehrheit: Mehrheit
  [AbstimmungDefault.ZUSTIMMUNG]: StimmenAndKapital
  [AbstimmungDefault.ABLEHNUNG]: StimmenAndKapital
  [AbstimmungDefault.ENTHALTUNG]: StimmenAndKapital
  options?: {
    [key: string]: StimmenAndKapital
  }
}

const useGetTOPAbstimmung = (): AbstimmungResult | false => {
  const moderationState = useModerationState()
  const currentTOP = useGetCurrentTOP()
  const [result, setResult] = useState<AbstimmungResult>()

  useEffect(() => {
    const getStimmenAndKapitalByType = (type: Abstimmung, ergebnisTOP: TagesordnungspunktErgebnis) => {
      if (!moderationState || !currentTOP) return
      const { company, versammlungErgebnis } = moderationState

      // get matching beteiligungen
      const beteiligungenOfType = ergebnisTOP.ergebnisse
        .filter((e) => {
          if (!e.anwesend) return false
          if (type === AbstimmungDefault.ZUSTIMMUNG) {
            if (company.mehrheit.enthaltungenBeiGesellschafterversammlungen === Enthaltung.ZUSTIMMUNG) {
              return e.abstimmung === AbstimmungDefault.ENTHALTUNG || e.abstimmung === AbstimmungDefault.ZUSTIMMUNG
            }
          } else if (type === AbstimmungDefault.ABLEHNUNG) {
            if (company.mehrheit.enthaltungenBeiGesellschafterversammlungen === Enthaltung.GEGENSTIMME) {
              return e.abstimmung === AbstimmungDefault.ENTHALTUNG || e.abstimmung === AbstimmungDefault.ABLEHNUNG
            }
          }
          return e.abstimmung === type
        })
        .map((e) => e.beteiligungUUID)

      // get stimmen
      const stimmen = beteiligungenOfType.reduce((acc, beteiligungUUID) => {
        const beteiligung = versammlungErgebnis.absoluteTeilnehmer.find((b) => b.uuid === beteiligungUUID)
        if (!beteiligung) return acc
        return acc + beteiligung.stimmen
      }, 0)

      // get kapital
      const kapital = beteiligungenOfType.reduce((acc, beteiligungUUID) => {
        const beteiligung = versammlungErgebnis.absoluteTeilnehmer.find((b) => b.uuid === beteiligungUUID)
        if (!beteiligung) return acc
        return acc + beteiligung.beteiligungsHoehe
      }, 0)
      return { stimmen, kapital }
    }

    const getState = (top: Tagesordnungspunkt, topErgebnis: TagesordnungspunktErgebnis, zustimmung: StimmenAndKapital) => {
      if (!moderationState) return false
      const { versammlungErgebnis } = moderationState
      let totalStimmen = versammlungErgebnis.absoluteTeilnehmer.reduce((acc, beteiligung) => acc + beteiligung.stimmen, 0)
      let totalBeteiligungAmount = versammlungErgebnis.absoluteTeilnehmer.length
      let totalVotedBeteiligungAmount = topErgebnis.ergebnisse.filter((e) => !!e.abstimmung && e.anwesend).length
      if (top.mehrheit.absoluteStimmenVerwenden === AbsoluteState.RELATIVE) {
        totalStimmen = versammlungErgebnis.absoluteTeilnehmer.reduce((acc, beteiligung) => {
          const hasVoted = topErgebnis.ergebnisse.some((e) => e.beteiligungUUID === beteiligung.uuid && e.anwesend)
          if (hasVoted) {
            return acc + beteiligung.stimmen
          }
          return acc
        }, 0)
        totalBeteiligungAmount = topErgebnis.ergebnisse.filter((e) => e.anwesend).length
      }
      const minStimmen = (totalStimmen / 100) * top.mehrheit.minPercent
      const state: State = {
        zustimmung: zustimmung.stimmen > minStimmen,
        minStimmen,
        totalStimmen,
        totalBeteiligungAmount,
        totalVotedBeteiligungAmount,
        currentZustimmungStimmen: zustimmung.stimmen,
        currentZustimmungPercent: totalStimmen > 0 ? (100 / totalStimmen) * zustimmung.stimmen : 0,
      }
      return state
    }

    if (!moderationState || !currentTOP) return
    const { top, ergebnisTOP } = currentTOP
    if (!!top.mehrheit) {
      const stimmenAndKapitalZustimmung = getStimmenAndKapitalByType(AbstimmungDefault.ZUSTIMMUNG, ergebnisTOP)
      const stimmenAndKapitalAblehnung = getStimmenAndKapitalByType(AbstimmungDefault.ABLEHNUNG, ergebnisTOP)
      const stimmenAndKapitalEnthaltung = getStimmenAndKapitalByType(AbstimmungDefault.ENTHALTUNG, ergebnisTOP)
      const state = getState(top, ergebnisTOP, stimmenAndKapitalZustimmung) as State

      const result: AbstimmungResult = {
        state,
        mehrheit: top.mehrheit,
        [AbstimmungDefault.ZUSTIMMUNG]: stimmenAndKapitalZustimmung,
        [AbstimmungDefault.ABLEHNUNG]: stimmenAndKapitalAblehnung,
        [AbstimmungDefault.ENTHALTUNG]: stimmenAndKapitalEnthaltung,
      }
      if (top.type === TagesordnungspunktType.VOTING_OPTIONS) {
        result.options = top.votingOptions.reduce((acc, vO) => {
          acc[vO.uuid] = getStimmenAndKapitalByType(vO.uuid, ergebnisTOP)
          return acc
        }, {})
      }
      setResult(result)
    } else {
      setResult(null)
    }
  }, [moderationState, currentTOP])

  return result
}

export default useGetTOPAbstimmung
