import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useEffect, useState } from 'react'
import Input from '../Input/Input'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import Checkbox from '../Checkbox/Checkbox'
import Loading from '../Loading/Loading'
import { Company } from '../../../types/Company'

export type Criteria = {
  companyUUID?: string
}

type Props = {
  criteria?: Criteria
  selected: Company[]
  onSelect: (companyList: Company[]) => void
  single?: boolean
  excludedUUIDList?: string[]
}

const CompanySelect: React.FC<Props> = ({ criteria = {}, selected = [], single = false, onSelect, excludedUUIDList = [] }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [search, setSearch] = useState<string>('')
  const [companyList, setCompanyList] = useState<Company[]>()
  const list = functional.use(Routes.COMPANY_LIST)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const pull = async () => {
      setLoading(true)
      const result: Company[] = await list({ criteria })
      setLoading(false)
      if (Array.isArray(result)) {
        setCompanyList(result.filter((company) => !excludedUUIDList.includes(company.uuid)))
      }
    }
    if (!companyList) {
      pull()
    }
  }, [criteria, companyList, list, excludedUUIDList])

  const getName = (company: Company) => {
    return `${company.base.name}`
  }

  const filter = (company: Company) => {
    if (search) {
      return getName(company).toLowerCase().includes(search.toLowerCase())
    }
    return true
  }

  const isSelected = (company: Company) => !!selected.find((p) => p.uuid === company.uuid)

  const select = (company: Company) => () => {
    if (isSelected(company)) {
      onSelect(selected.filter((p) => p.uuid !== company.uuid))
    } else {
      if (single) {
        onSelect([company])
      } else {
        onSelect([...selected, company])
      }
    }
  }

  return (
    <div className="w-full flex flex-col gap-1">
      <Input label placeholder={t.UI.companySelect.search} value={search} onChange={setSearch} />
      <div className="flex flex-col gap-1 max-h-32 overflow-y-auto overflow-x-hidden">
        {!loading &&
          Array.isArray(companyList) &&
          companyList
            .filter(filter)
            .map((company) => <Checkbox key={company.uuid} label={getName(company)} checked={isSelected(company)} onChange={select(company)} />)}
        <Loading loading={!loading} />
      </div>
    </div>
  )
}

export default CompanySelect
