import { useSelector } from 'react-redux'
import styled from 'styled-components'
import props from '../../../../../../redux/props'
import Input from '../../../../../UI/Input/Input'
import { useState } from 'react'
import Button from '../../../../../UI/Button/Button'
import Form from '../../../../../UI/Form/Form'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Kontaktperson } from '../../../../../../types/Company'

const InputWrapper = styled.div`
  width: calc(100% / 2 - 5px);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

type Props = {
  data: Kontaktperson[]
  set: (list: Kontaktperson[]) => void
}

const ManualPersonList: React.FC<Props> = ({ data = [], set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [newPerson, setNewPerson] = useState<Kontaktperson>({})

  const setProp = (prop: keyof Kontaktperson) => (value: string) => setNewPerson({ ...newPerson, [prop]: value })

  const confirm = () => {
    set([...data, newPerson])
    setNewPerson({})
  }

  const getName = (person: Kontaktperson) => `${person.vorname || ''} ${person.nachname || ''} - ${person.mail || ''} - ${person.telefon || ''}`

  const remove = (index: number) => () => {
    set(data.filter((_, i) => i !== index))
  }

  return (
    <div>
      <label className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">
        {t.company.kontaktpersonen.add.external.title}
      </label>
      <Form onSubmit={confirm} className="flex flex-row flex-wrap gap-[10px] mt-1">
        <InputWrapper>
          <Input label placeholder={t.attributes.vorname} value={newPerson.vorname} onChange={setProp('vorname')} />
        </InputWrapper>
        <InputWrapper>
          <Input label placeholder={t.attributes.nachname} value={newPerson.nachname} onChange={setProp('nachname')} />
        </InputWrapper>
        <InputWrapper>
          <Input label placeholder={t.attributes.mail} value={newPerson.mail} onChange={setProp('mail')} />
        </InputWrapper>
        <InputWrapper>
          <Input label placeholder={t.attributes.telefon} value={newPerson.telefon} onChange={setProp('telefon')} />
        </InputWrapper>
        <Button type="submit" className="ml-auto" text={t.company.kontaktpersonen.add.external.confirm} />
      </Form>
      <div className="flex flex-col gap-1 mt-3">
        {data.map((person, i) => (
          <div key={i} className="flex flex-row justify-between gap-1 items-center">
            <div className="truncate">{getName(person)}</div>
            <div className="cursor-pointer" onClick={remove(i)}>
              <XMarkIcon className="w-6" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ManualPersonList
