import { useEffect, useState } from 'react'
import { Company } from '../../../../../types/Company'
import useListGesellschafter from '../../../../hooks/useListGesellschafter'

const useGesellschafterValidator = (data: Company): boolean => {
  const gesellschafter = useListGesellschafter(data?.uuid)
  const [status, setStatus] = useState<boolean>(false)

  useEffect(() => {
    if (!!data && !!gesellschafter) {
      const kapitalGesamt = data.dynamic.kapitalGesamt
      const stimmenGesamt = data.dynamic.stimmenGesamt
      const kapitalGesellschafter = gesellschafter?.reduce((acc, curr) => acc + curr.beteiligungsHoehe, 0)
      const stimmenGesellschafter = gesellschafter?.reduce((acc, curr) => acc + curr.stimmen, 0)
      setStatus(kapitalGesamt === kapitalGesellschafter && stimmenGesamt === stimmenGesellschafter)
    }
  }, [gesellschafter, data])

  return status
}

export default useGesellschafterValidator
