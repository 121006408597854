import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import Button from '../../../../../UI/Button/Button'
import Modal from '../../../../../UI/Modal/Modal'
import { Mehrheit } from '../../../../../../types/Mehrheit'
import Input from '../../../../../UI/Input/Input'
import MehrheitInput from '../../../../../UI/Input/Mehrheit'
import Number from '../../../../../UI/Input/Number'
import Textarea from '../../../../../UI/Textarea/Textarea'
import Toggle from '../../../../../UI/Toggle/Toggle'

type Props = {
  callback: (value: Mehrheit) => void
  deleteCallback?: () => void
  initData?: Mehrheit
  children?: React.ReactNode
}

const Upsert: React.FC<Props> = ({ callback, deleteCallback, children, initData = {} as Mehrheit }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<Mehrheit>(initData)

  const toggle = () => setOpen(!open)

  const set = (key: keyof Mehrheit) => (value: any) => setData({ ...data, [key]: value } as Mehrheit)

  const confirm = () => {
    callback(data)
    if (initData.name === undefined) {
      setData({} as Mehrheit)
    }
    toggle()
  }

  return (
    <>
      {children ? (
        <div className="cursor-pointer" onClick={toggle}>
          {children}
        </div>
      ) : (
        <Button onClick={toggle} text={t.company.mehrheiten.upsert.createCTA} />
      )}
      <Modal
        onConfirm={confirm}
        show={open}
        onClose={toggle}
        title={!!initData ? t.company.mehrheiten.upsert.titleUpdate : t.company.mehrheiten.upsert.titleCreate}
      >
        <div className="text-blue font-bold">{t.company.mehrheiten.upsert.title}</div>
        <div className="flex flex-col gap-2 mt-3">
          <Input label placeholder={t.attributes.name} value={data.name} onChange={set('name')} required />
          <MehrheitInput value={data.absoluteStimmenVerwenden} onChange={set('absoluteStimmenVerwenden')} required />
          <Number
            suffix={t.generic.percent}
            label
            placeholder={t.attributes.minPercent}
            value={data.minPercent}
            onChange={set('minPercent')}
            max={100}
            required
          />
          <Textarea placeholder={t.attributes.text} value={data.text} onChange={set('text')} />
          <Toggle label={t.attributes.alsStandartVerwenden} value={data['alsStandartVerwenden']} onChange={set('alsStandartVerwenden')} />
          {!!deleteCallback && <Button danger onClick={deleteCallback} text={t.generic.delete} />}
        </div>
      </Modal>
    </>
  )
}

export default Upsert
