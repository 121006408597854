import { crud } from '@think-internet/zeus-frontend-package'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BuildingOffice2Icon } from '@heroicons/react/24/outline'
import props from '../../../../../redux/props'
import { Company } from '../../../../../types/Company'
import { useParams } from 'react-router-dom'
import MediumContainer from '../../../../UI/Container/MediumContainer'
import SideNavigation from '../../../../UI/Layout/SideNavigation/SideNavigation'
import Accordion, { SectionItem } from '../../../../UI/Accordion/Accordion'
import DateTimeLocation from './Sections/DateTimeLocation'
import Tagesordnungspunkte from './Sections/Tagesordnungspunkte/Tagesordnungspunkte'
import Einladung from './Sections/Einladung'
import Save from '../../../../UI/Save/Save'
import useToast, { Type as ToastType } from '../../../../hooks/useToast'
import useHasChanged from '../../../../hooks/useHasChanged'
import { defaultEinladungsfrist, getUUID } from '../../../../../utility'
import Freigabe from './Sections/Freigabe/Freigabe'
import { StandortType, Type, Versammlung } from '../../../../../types/Versammlung/Versammlung'

const Upsert = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const companyFeature = crud.use(props.COMPANY)
  const [company, setCompany] = useState<Company>(null)
  const { companyUUID, type, versammlungUUID } = useParams()
  const [data, setData] = useState<Versammlung>(null)
  const versammlungFeature = crud.use(props.VERSAMMLUNG)
  const toast = useToast()
  const hasChanged = useHasChanged(data)

  useEffect(() => {
    const pull = async () => {
      const result = await companyFeature.get({ uuid: companyUUID })
      if (result) {
        setCompany(result)
      }
    }
    const pullVersammlung = async () => {
      const versammlung = await versammlungFeature.get({ uuid: versammlungUUID })
      if (versammlung) {
        setData(versammlung)
        hasChanged.init(versammlung)
      }
    }
    if (!company) {
      pull()
    } else if (!data) {
      if (!!versammlungUUID) {
        pullVersammlung()
      } else {
        const einladungsfrist = company?.einladungsfrist?.inTagen || defaultEinladungsfrist
        const datumUhrzeit = new Date()
        datumUhrzeit.setDate(datumUhrzeit.getDate() + einladungsfrist)
        const data: Versammlung = {
          companyUUID: company.uuid,
          datumUhrzeit: datumUhrzeit,
          abgabefrist: datumUhrzeit,
          type: type as Type,
          standortType: [StandortType.OFFLINE],
          tagesordnungspunkte: [
            {
              key: getUUID(),
            },
          ],
          einladung: {},
          freigabe: {
            testMailVerschickt: false,
          },
        }
        setData(data)
        hasChanged.init(data)
      }
    }
  }, [company, companyFeature, companyUUID, type, data, setData, hasChanged, versammlungUUID, versammlungFeature, toast, t])

  const set = (key: string) => (value: any) => setData({ ...data, [key]: value })

  const getItems = (): SectionItem[] => [
    {
      title: t.versammlung.upsert.sections.dateTimeLocation,
      content: <DateTimeLocation company={company} data={data} set={set} />,
      id: 'dateTimeLocation',
    },
    {
      title: t.versammlung.upsert.sections.tagesordnungspunkte,
      content: <Tagesordnungspunkte company={company} data={data} set={set} />,
      id: 'tagesordnungspunkte',
    },
    {
      title: t.versammlung.upsert.sections.einladung,
      content: <Einladung company={company} data={data} set={set} />,
      id: 'einladung',
    },
    {
      title: t.versammlung.upsert.sections.freigabe,
      content: <Freigabe company={company} data={data} set={set} />,
      status: !!data.freigabe.status,
      id: 'freigabe',
    },
  ]

  const savedCallback = (versammlung: Versammlung) => {
    setData(versammlung)
    hasChanged.init(versammlung)
    toast(t.versammlung.upsert.success, ToastType.SUCCESS)
  }

  if (!company || !data) return <></>
  return (
    <div className="mt-10 pb-20">
      <MediumContainer>
        <a href={`/admin/company/${company.uuid}`} className="overflow-hidden flex gap-3 items-center justify-start">
          <BuildingOffice2Icon className="fill-blue w-10 shrink-0" />
          <div className="font-bold text-lg text-blue truncate">{`${company.base.name} - ${t.versammlung[type]}${t.versammlung.upsert.titleSuffix}`}</div>
        </a>
      </MediumContainer>
      <MediumContainer>
        <div className="relative mt-3">
          <SideNavigation uuid={company.uuid} items={t.company.navigation} />
          <Accordion initIndex={0} items={getItems()} />
        </div>
      </MediumContainer>
      <Save data={data} upsert={versammlungFeature.upsert} hasChanged={hasChanged.hasChanged} successCallback={savedCallback} />
    </div>
  )
}

export default Upsert
