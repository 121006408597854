import { Tagesordnungspunkt } from '../../../../../../../types/Versammlung/Versammlung'
import { ActiveTOPEnum, ModerationState } from '../../../../../../../types/Versammlung/Moderation'
import { Tagesordnungspunkt as TagesordnungspunktErgebnis } from '../../../../../../../types/Versammlung/VersammlungErgebnis'

export type GetTOPReturn = {
  top: Tagesordnungspunkt
  ergebnisTOP: TagesordnungspunktErgebnis
}

const getTOP = (moderationState: ModerationState): GetTOPReturn => {
  const { versammlung, versammlungErgebnis, activeTOP } = moderationState
  const topNumber = activeTOP === ActiveTOPEnum.START ? 0 : activeTOP
  const top = versammlung.tagesordnungspunkte[topNumber]
  const ergebnisTOP = versammlungErgebnis.tagesordnungspunkte[topNumber]
  return { top, ergebnisTOP }
}

export default getTOP
