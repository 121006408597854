import { crud } from '@think-internet/zeus-frontend-package'
import { Person } from '../../../types/Person'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Input from '../../UI/Input/Input'
import Container from '../../UI/Container/Container'
import { UserGroupIcon } from '@heroicons/react/24/solid'
import Button from '../../UI/Button/Button'
import Toggle from '../../UI/Toggle/Toggle'

const List = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const personFeature = crud.use(props.PERSON)
  const [list, setList] = useState<Person[]>(null)
  const [search, setSearch] = useState<string>('')
  const [showInactive, setShowInactive] = useState<boolean>(false)
  const [showDeath, setShowDeath] = useState<boolean>(false)

  const pull = useCallback(async () => {
    const result: Person[] = await personFeature.list({})
    if (Array.isArray(result)) {
      setList(result)
      setSearch('')
    }
  }, [personFeature])

  useEffect(() => {
    if (!list) {
      pull()
    }
  }, [list, pull])

  const filter = (person: Person) => {
    const textFilter =
      person.kontakt.vorname.toLowerCase().includes(search.toLowerCase()) ||
      person.kontakt.nachname.toLowerCase().includes(search.toLowerCase()) ||
      person.login.identifier.toLowerCase().includes(search.toLowerCase())
    const inactiveFilter = showInactive ? person.inaktivitaet.inaktivitaet : !person.inaktivitaet.inaktivitaet
    const deathFilter = showDeath ? person.todesfall.aktiv : !person.todesfall.aktiv
    if (showInactive && showDeath) {
      return textFilter && (inactiveFilter || deathFilter)
    }
    return textFilter && inactiveFilter && deathFilter
  }

  const getName = (person: Person) => {
    if (!person.login.identifier) return `${person.kontakt.vorname} ${person.kontakt.nachname}`
    return `${person.kontakt.vorname} ${person.kontakt.nachname} - ${person.login.identifier}`
  }

  if (!list) return <></>
  return (
    <Container>
      <div className="py-10">
        <div className="flex gap-3 items-center justify-between">
          <div className="flex gap-3 items-center">
            <UserGroupIcon className="fill-blue w-10" />
            <div className="font-bold text-lg text-blue">{t.person.list.title}</div>
          </div>
          <Button href="/admin/person/create" text={t.person.upsert.createCTA} />
        </div>
        <Input placeholder={t.person.list.search} onChange={setSearch} value={search} className="mt-3" />
        <div className="flex flex-col lg:flex-row mt-3">
          <div className="flex-1">
            <Toggle label={t.person.list.showInactive} value={showInactive} onChange={setShowInactive} />
          </div>
          <div className="flex-1">
            <Toggle label={t.person.list.showDeath} value={showDeath} onChange={setShowDeath} />
          </div>
        </div>
        <div className="flex flex-col gap-1 mt-3">
          {list.filter(filter).map((person) => (
            <a
              href={`/admin/person/${person.uuid}`}
              key={person.uuid}
              className="flex gap-3 items-center bg-turquoise px-2 py-1 text-white rounded-lg"
            >
              {getName(person)}
            </a>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default List
