export enum Status {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

export type StatusObject =
  | {
      missingNEEDED: string[]
      missingOPTIONAL: string[]
    }
  | boolean

const StatusBell: React.FC<{ status: StatusObject }> = ({ status }) => {
  if (typeof status === 'undefined' || status === null) return <></>

  const getColor = () => {
    if (typeof status === 'boolean') return status ? 'bg-green' : 'bg-red'
    if (status.missingNEEDED.length > 0) {
      return 'bg-red'
    }
    if (status.missingOPTIONAL.length > 0) {
      return 'bg-orange'
    }
    return 'bg-green'
  }

  return <div className={`w-4 aspect-square rounded-full ${getColor()}`}></div>
}

export default StatusBell
