import { useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'
import { ModerationState, moderationStateProp } from '../../../../../../types/Versammlung/Moderation'

type Save = (moderationState?: ModerationState) => Promise<ModerationState | false>

type Return = Save

const useSave = (): Return => {
  const moderationState: ModerationState = useSelector((s) => s[moderationStateProp])
  const submit = functional.use(Routes.VERSAMMLUNG_SAVE)

  const save: Save = async (updatedModerationState) => {
    if (!moderationState && !updatedModerationState) return false
    const versammlungErgebnis = await submit({
      versammlungErgebnis: updatedModerationState ? updatedModerationState.versammlungErgebnis : moderationState.versammlungErgebnis,
    })
    if (!versammlungErgebnis) return false
    if (updatedModerationState) {
      return { ...updatedModerationState, versammlungErgebnis }
    } else {
      return { ...moderationState, versammlungErgebnis }
    }
  }

  return save
}

export default useSave
