export type AuthLevelRequired = Array<Level> | false

export type Role = {
  uuid: string
  name: string
}

export enum Level {
  GOD = 'GOD',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}
