import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import Button from '../../../../../UI/Button/Button'
import Modal from '../../../../../UI/Modal/Modal'
import { SectionProps } from '../../Upsert'
import PersonSelect, { Criteria } from '../../../../../UI/PersonSelect/PersonSelect'
import { Person } from '../../../../../../types/Person'
import { Beschlussfaehigkeit } from '../../../../../../types/Company'

const Add: React.FC<SectionProps<Beschlussfaehigkeit['bestimmteKomplementaerePersonList']>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)

  const [personList, setPersonList] = useState<Person[]>(data || [])

  const toggle = () => setOpen(!open)

  const confirm = async () => {
    set(personList)
    toggle()
  }

  const getCriteria = (): Criteria => {
    return { companyUUID: company?.uuid }
  }

  return (
    <>
      <Button onClick={toggle} text={t.company.kontaktpersonen.add.cta} />
      <Modal
        noForm
        large
        onConfirm={confirm}
        show={open}
        onClose={toggle}
        title={t.company.beschlussfaehigkeit.bestimmteKomplementaerePersonList.add.title}
      >
        <div className="flex flex-col md:flex-row gap-10 md:gap-3 mt-3">
          <div className="flex-1">
            <PersonSelect criteria={getCriteria()} selected={personList} onSelect={setPersonList} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Add
