import props from '../../../../../../redux/props'
import { useSelector } from 'react-redux'
import { InputWrapper, SectionProps } from '../../Upsert'
import Section from '../../../../../UI/Accordion/Section/Section'
import Enthaltung from '../../../../../UI/Input/Enthaltung'
import { useCallback, useEffect } from 'react'
import { Mehrheit as MehrheitSingleType } from '../../../../../../types/Mehrheit'
import { Mehrheit as MehrheitType } from '../../../../../../types/Company'
import Upsert from './Upsert'
import { PencilIcon } from '@heroicons/react/24/solid'
import { getMehrheitName } from '../../../../../../utility'

const Mehrheit: React.FC<SectionProps<MehrheitType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const setInternal = useCallback(
    (key: keyof MehrheitType) => (value: any) => {
      const newData = { ...data, [key]: value }
      set(newData)
    },
    [data, set],
  )

  useEffect(() => {
    if (!data) {
      set({ custom: [] })
    }
  }, [data, set])

  const updateCustom = (index: number | null) => (mehrheit: MehrheitSingleType) => {
    const newCustom = data.custom || []
    if (mehrheit.alsStandartVerwenden) {
      newCustom.forEach((m) => (m.alsStandartVerwenden = false))
    }
    if (index === null) {
      setInternal('custom')(data.custom.concat(mehrheit))
    } else {
      setInternal('custom')(data.custom.map((m, i) => (i === index ? mehrheit : m)))
    }
  }

  const deleteCustom = (index: number) => () => {
    setInternal('custom')(data.custom.filter((_, i) => i !== index))
  }

  if (!data) return <></>
  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <Section title={t.company.mehrheiten.help.title} light>
        {t.company.mehrheiten.help.items.map((item: { title: string; text: string }, i: number) => (
          <div key={i}>
            <div className="font-bold">{item.title}</div>
            <div className="">{item.text}</div>
          </div>
        ))}
      </Section>
      <InputWrapper>
        <Enthaltung
          title={t.attributes.enthaltungenImSchriftlichenAbstimmungsverfahren}
          value={data.enthaltungenImSchriftlichenAbstimmungsverfahren}
          onChange={setInternal('enthaltungenImSchriftlichenAbstimmungsverfahren')}
        />
      </InputWrapper>
      <InputWrapper>
        <Enthaltung
          title={t.attributes.enthaltungenBeiGesellschafterversammlungen}
          value={data.enthaltungenBeiGesellschafterversammlungen}
          onChange={setInternal('enthaltungenBeiGesellschafterversammlungen')}
        />
      </InputWrapper>
      <div className="w-full">
        <div className="text-white text-sm py-1 px-2 rounded-full block mb-1 font-bold bg-turquoise w-full">{t.company.mehrheiten.custom.title}</div>
        {Array.isArray(data.custom) && data.custom.length === 0 && <div className="text-center text-sm">{t.company.mehrheiten.custom.empty}</div>}
        {Array.isArray(data.custom) && data.custom.length > 0 && (
          <div className="flex flex-col gap-1">
            {data.custom.map((mehrheit, i) => (
              <div className="flex justify-between items-center bg-lightGray px-2 py-1 w-full rounded-full" key={mehrheit.name}>
                <div>{getMehrheitName(t, mehrheit, true)}</div>
                <Upsert key={i} callback={updateCustom(i)} initData={mehrheit} deleteCallback={deleteCustom(i)}>
                  <PencilIcon className="h-5 w-5 text-blue" />
                </Upsert>
              </div>
            ))}
          </div>
        )}
        <div className="flex justify-end mt-1">
          <Upsert callback={updateCustom(null)} />
        </div>
      </div>
    </div>
  )
}

export default Mehrheit
