import { useSelector } from 'react-redux'
import LargeContainer from '../../../../../UI/Container/LargeContainer'
import props from '../../../../../../redux/props'
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import useNavigation from '../hooks/useNavigation'

const Navigate = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { hasNext, next, hasPrevious, previous } = useNavigation()

  return (
    <div className="fixed bottom-0 left-0 w-full py-3 bg-turquoise">
      <LargeContainer>
        <div className="flex justify-end items-center gap-5">
          {hasPrevious() !== false && (
            <div className="flex flex-row gap-2 text-white font-bold items-center cursor-pointer" onClick={previous}>
              <ArrowLeftCircleIcon className="fill-white w-8" />
              {t.versammlung.moderate.navigate.back}
            </div>
          )}
          {hasNext() !== false && (
            <div className="flex flex-row gap-2 text-white font-bold items-center cursor-pointer" onClick={() => next()}>
              <ArrowRightCircleIcon className="fill-white w-8" />
              {t.versammlung.moderate.navigate.next}
            </div>
          )}
        </div>
      </LargeContainer>
    </div>
  )
}

export default Navigate
