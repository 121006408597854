import { Abstimmung } from '../../../../../../../../types/Versammlung/VersammlungErgebnis'
import useGetCurrentTOP from '../../../hooks/useGetCurrentTOP'
import { TagesordnungspunktType } from '../../../../../../../../types/Versammlung/Versammlung'
import Default from './Default'
import Options from './Options'

type Props = {
  id: string
  abstimmung: Abstimmung
  set: (abstimmung: Abstimmung) => void
}

const Toggles: React.FC<Props> = ({ id, abstimmung, set }) => {
  const currentTop = useGetCurrentTOP()
  if (!currentTop) return <div></div>
  const { top } = currentTop

  if (top.type === TagesordnungspunktType.VOTING_DEFAULT) {
    return <Default abstimmung={abstimmung} set={set} />
  } else if (top.type === TagesordnungspunktType.VOTING_OPTIONS) {
    return <Options id={top.key + id} abstimmung={abstimmung} set={set} top={top} />
  }
}

export default Toggles
