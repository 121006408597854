import { useSelector } from 'react-redux'
import styled from 'styled-components'
import props from '../../../../../../../redux/props'
import { AbstimmungResult } from '../../hooks/useGetTOPAbstimmung'
import { formatNumber, getMehrheitName } from '../../../../../../../utility'
import { Tagesordnungspunkt, TagesordnungspunktType } from '../../../../../../../types/Versammlung/Versammlung'

export const ItemWrapper = styled.div<{ itemsPerRow?: number }>`
  width: ${({ itemsPerRow: i }) => (i ? `calc(100% / ${i} - 10px * ${i - 1} / ${i})` : 'calc(100% / 2 - 5px)')};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

type Props = { top: Tagesordnungspunkt; abstimmung: AbstimmungResult }

const Mehrheit: React.FC<Props> = ({ top, abstimmung }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <ItemWrapper>
        <div className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">
          {t.versammlung.moderate.detail.mehrheit.required}
        </div>
        <div>{getMehrheitName(t, abstimmung?.mehrheit)}</div>
        <div>
          {t.versammlung.moderate.detail.mehrheit.stimmen.minPrefix}
          {formatNumber(abstimmung.state.minStimmen)}
          {t.versammlung.moderate.detail.mehrheit.stimmen.suffix}
        </div>
      </ItemWrapper>
      {top.type === TagesordnungspunktType.VOTING_DEFAULT && (
        <ItemWrapper>
          <div className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">
            {t.versammlung.moderate.detail.mehrheit.current}
          </div>
          <div>{`${formatNumber(Math.round(abstimmung.state.currentZustimmungPercent))}% - ${formatNumber(
            abstimmung.state.currentZustimmungStimmen,
          )}${t.versammlung.moderate.detail.mehrheit.stimmen.mid}${formatNumber(abstimmung.state.totalStimmen)}${
            t.versammlung.moderate.detail.mehrheit.stimmen.suffix
          }`}</div>
        </ItemWrapper>
      )}
      {top.type === TagesordnungspunktType.VOTING_OPTIONS && abstimmung.options && (
        <ItemWrapper>
          <div className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">
            {t.versammlung.moderate.detail.mehrheit.current}
          </div>
          <div>
            {top.votingOptions.map((vO) => (
              <div key={vO.uuid}>
                {vO.name}: {formatNumber(Math.round((100 / abstimmung.state.totalStimmen) * abstimmung.options[vO.uuid].stimmen))}% -{' '}
                {formatNumber(abstimmung.options[vO.uuid].stimmen)}
                {t.versammlung.moderate.detail.mehrheit.stimmen.mid}
                {formatNumber(abstimmung.state.totalStimmen)}
                {t.versammlung.moderate.detail.mehrheit.stimmen.suffix}
              </div>
            ))}
          </div>
        </ItemWrapper>
      )}
      <ItemWrapper>
        <div className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">
          {t.versammlung.moderate.detail.mehrheit.votingAmount}
        </div>
        <div>{`${formatNumber(abstimmung.state.totalVotedBeteiligungAmount)}${
          t.versammlung.moderate.detail.mehrheit.beteiligungAmount.mid
        }${formatNumber(abstimmung.state.totalBeteiligungAmount)}${t.versammlung.moderate.detail.mehrheit.beteiligungAmount.suffix}`}</div>
      </ItemWrapper>
    </div>
  )
}

export default Mehrheit
