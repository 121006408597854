export enum Enthaltung {
  NICHT_ABGEGEBEN = 'NICHT_ABGEGEBEN',
  GEGENSTIMME = 'GEGENSTIMME',
  ZUSTIMMUNG = 'ZUSTIMMUNG',
}

export enum AbsoluteState {
  ABSOLUTE = 'ABSOLUTE',
  RELATIVE = 'RELATIVE',
}

export type Mehrheit = {
  name: string
  absoluteStimmenVerwenden: AbsoluteState
  minPercent: number
  text: string
  alsStandartVerwenden: boolean
}
