import useModerationState from './useModerationState'
import { useEffect, useState } from 'react'
import getTOP, { GetTOPReturn } from './helper/getTOP'

const useGetCurrentTOP = (): GetTOPReturn => {
  const moderationState = useModerationState()
  const [result, setResult] = useState<GetTOPReturn>(null)

  useEffect(() => {
    if (!moderationState) return
    const result = getTOP(moderationState)
    if (result) {
      setResult(result)
    }
  }, [moderationState])

  return result
}

export default useGetCurrentTOP
