import { useState } from 'react'
import { Uebertragung } from '../../../types/Uebertragung'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import props from '../../../redux/props'
import { functional } from '@think-internet/zeus-frontend-package'
import { useSelector } from 'react-redux'
import Input from '../Input/Input'
import Currency from '../Input/Currency'
import Number from '../Input/Number'
import Toggle from '../Toggle/Toggle'
import AddFiles from '../AddFiles/AddFiles'
import { UPLOAD_TYPES } from '../DirectFileUpload/DirectFileUpload'
import { File } from '../../../types/generic'
import useToast, { Type } from '../../hooks/useToast'
import { formatEUR, formatNumber } from '../../../utility'
import BeteiligungSelect from '../BeteiligungSelect/BeteiligungSelect'
import Routes from '../../../redux/routes'
import CompanySelect from '../CompanySelect/CompanySelect'
import PersonSelect from '../PersonSelect/PersonSelect'
import OptionSelect from '../OptionSelect/OptionSelect'

type Props = {
  personUUID?: string
  companyUUID?: string
  callback: (uebertragung: Uebertragung) => void
}

type InternalUebertragung = Uebertragung & { confirmation?: boolean }

const Add: React.FC<Props> = ({ personUUID, companyUUID, callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<InternalUebertragung>({} as InternalUebertragung)
  const [open, setOpen] = useState<boolean>(false)
  const uebertragungCreate = functional.use(Routes.UEBERTRAGUNG_CREATE)
  const toast = useToast()
  const [targetType, setTargetType] = useState<'company' | 'person'>()

  const confirm = async () => {
    if (data.originBeteiligung) {
      if (data.targetCompanyUUID || data.targetPersonUUID) {
        const result = await uebertragungCreate({ data })
        if (result) {
          callback(result)
          toast(t.UI.uebertragungManager.add.success, Type.SUCCESS)
          setOpen(false)
        } else {
          toast(t.UI.uebertragungManager.add.error.generic, Type.ERROR)
        }
      } else {
        toast(t.UI.uebertragungManager.add.error.targetMissing, Type.ERROR)
      }
    } else {
      toast(t.UI.uebertragungManager.add.error.originBeteiligungMissing, Type.ERROR)
    }
  }
  const toggle = () => setOpen(!open)

  const set = (prop: keyof InternalUebertragung) => (value: any) => {
    setData({ ...data, [prop]: value })
  }

  const setList = (listProp: keyof Uebertragung) => (selectedList: any[]) => {
    if (selectedList.length > 0) {
      setData({ ...data, [`${listProp}UUID`]: selectedList[0].uuid, [listProp]: selectedList[0] })
    } else {
      setData({ ...data, [`${listProp}UUID`]: null, [listProp]: null })
    }
  }

  const removeFile = (file: File) => () => {
    const newFiles = data.files.filter((f) => f.key !== file.key)
    set('files')(newFiles)
  }

  const updateTargetType = (value: 'company' | 'person') => {
    setTargetType(value)
    if (value === 'company') {
      setData({ ...data, targetPersonUUID: null, targetPerson: null })
    } else {
      setData({ ...data, targetCompanyUUID: null, targetCompany: null })
    }
  }

  return (
    <>
      <Button onClick={toggle} text={t.UI.uebertragungManager.add.cta} />
      <Modal large onConfirm={confirm} show={open} onClose={toggle} title={t.UI.uebertragungManager.add.createTitle}>
        <div className="flex flex-col gap-3">
          <BeteiligungSelect
            single
            criteria={personUUID ? { personUUID } : { companyUUID }}
            selected={data.originBeteiligung ? [data.originBeteiligung] : []}
            onSelect={setList('originBeteiligung')}
          />
          {data.originBeteiligung && (
            <div>
              <div>{t.UI.uebertragungManager.add.targetTitle}</div>
              <OptionSelect
                options={[
                  { name: t.UI.uebertragungManager.add.targetCompany, value: 'company' },
                  { name: t.UI.uebertragungManager.add.targetPerson, value: 'person' },
                ]}
                selected={targetType}
                onSelect={updateTargetType}
              />
              <div className="mt-3">
                {targetType === 'company' && (
                  <CompanySelect single criteria={{}} selected={data.targetCompany ? [data.targetCompany] : []} onSelect={setList('targetCompany')} />
                )}
                {targetType === 'person' && (
                  <PersonSelect single criteria={{}} selected={data.targetPerson ? [data.targetPerson] : []} onSelect={setList('targetPerson')} />
                )}
              </div>
            </div>
          )}
          {(data.targetCompany || data.targetPerson) && (
            <>
              <Currency
                label
                placeholder={`${t.attributes.beteiligungsHoehe} (max. ${formatEUR(data.originBeteiligung.beteiligungsHoehe)})`}
                value={data.beteiligungsHoehe}
                onChange={set('beteiligungsHoehe')}
              />
              <Number
                label
                placeholder={`${t.attributes.stimmen} (max. ${formatNumber(data.originBeteiligung.stimmen)})`}
                value={data.stimmen}
                onChange={set('stimmen')}
              />
              <Input
                type="date"
                required
                label
                placeholder={t.attributes.anfangszeitpunkt}
                value={data.anfangszeitpunkt}
                onChange={set('anfangszeitpunkt')}
              />
              <AddFiles
                id={personUUID || companyUUID}
                removeCallback={removeFile}
                uploadType={UPLOAD_TYPES.UEBERTRAGUNG}
                files={data.files}
                onFiles={set('files')}
              />
              <Toggle required label={t.UI.uebertragungManager.add.checkHint} value={data.confirmation} onChange={set('confirmation')} />
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default Add
