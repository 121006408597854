import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Beteiligung } from '../../../types/Beteiligung'
import { formatEUR, formatNumber } from '../../../utility'
import Upsert from './Upsert'

type Props = {
  beteiligungen: Beteiligung[]
  callback: (beteiligung: Beteiligung) => void
  pullList: () => void
}

const List: React.FC<Props> = ({ beteiligungen, callback, pullList }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div>
      <div className="flex flex-row gap-3 w-full">
        <label className="w-1/3 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.name}</label>
        <label className="w-1/3 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.beteiligungsHoehe}</label>
        <label className="w-1/3 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.stimmen}</label>
        <div className="w-20 shrink-0 truncate"></div>
      </div>
      <div className="flex flex-col gap-1">
        {Array.isArray(beteiligungen) && beteiligungen.length > 0 ? (
          beteiligungen.map((beteiligung, index) => (
            <div className="flex flex-row gap-3 w-full" key={index}>
              <div className="flex-1 shrink-0 truncate">{beteiligung.targetCompany.base.name}</div>
              <div className="flex-1 shrink-0 truncate">{formatEUR(beteiligung.beteiligungsHoehe)}</div>
              <div className="flex-1 shrink-0 truncate">{formatNumber(beteiligung.stimmen)}</div>
              <div className="w-20 shrink-0 truncate">
                <Upsert initData={beteiligung} callback={callback} pullList={pullList} />
              </div>
            </div>
          ))
        ) : (
          <div className="mt-2 text-center text-sm">{t.UI.beteiligungManager.list.empty}</div>
        )}
      </div>
    </div>
  )
}

export default List
