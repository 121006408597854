type Props = {
  uuid: string
  items: {
    name: string
    link: (uuid: string) => string
  }[]
}

const SideNavigation: React.FC<Props> = ({ uuid, items }) => {
  if (!uuid) return null
  return (
    <div className="2xl:absolute right-[105%] flex flex-col gap-2 top-0">
      {items.map((i) => (
        <a href={i.link(uuid)} className="bg-turquoise text-white rounded-full px-3 py-1 whitespace-nowrap" key={i.name}>
          {i.name}
        </a>
      ))}
    </div>
  )
}

export default SideNavigation
