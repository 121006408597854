import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import StatusBell, { StatusObject } from './StatusBell'
import StatusProperties from './StatusProperties'

type Props = {
  open?: boolean
  title: React.ReactNode | string
  children: React.ReactNode | string | string[]
  clickedCallback?: () => void
  isLast?: boolean
  isFirst?: boolean
  className?: string
  fitTitle?: boolean
  status?: StatusObject | boolean
  light?: boolean
  secondary?: boolean
  draggableItem?: React.ReactNode
  ref?: any
}

const IconWrapper = styled.div<{ isActive: boolean }>`
  transform: rotate(${(props) => (props.isActive ? '180deg' : '0deg')});
  transition: transform 0.3s;
`

const Section: React.FC<Props> = ({
  open,
  title,
  children,
  clickedCallback,
  status,
  light = false,
  secondary = false,
  className = '',
  draggableItem = null,
  ref,
}) => {
  const [internalOpen, setInternalOpen] = useState<boolean>(open || false)

  useEffect(() => {
    if (open !== undefined) {
      setInternalOpen(open)
    }
  }, [open])

  const click = () => {
    if (open !== undefined) {
      clickedCallback && clickedCallback()
    } else {
      setInternalOpen(!internalOpen)
    }
  }

  return (
    <div className={`h-fit ${className}`} ref={ref}>
      <div
        className={`flex ${
          light ? 'bg-lightGray' : secondary ? 'bg-turquoise' : 'bg-blue'
        } items-center px-2 py-1 cursor-pointer w-full rounded-full`}
        onClick={click}
      >
        <div className={`w-10/12 lg:w-11/12 lg:truncate px-2 ${light ? '' : 'text-white'} flex items-center gap-2`}>
          <StatusBell status={status} />
          {title}
        </div>
        <div className="w-2/12 lg:w-1/12 flex justify-end">
          <IconWrapper isActive={internalOpen}>
            <ChevronDownIcon className={`w-6 ${light ? 'fill-blue' : 'fill-white'} transition-all`} />
          </IconWrapper>
        </div>
        {draggableItem && draggableItem}
      </div>
      <div className={`content ${internalOpen ? 'h-fit' : 'h-0 overflow-hidden'} max-h-fit`}>
        <div className="px-2 py-2">
          {typeof status === 'object' && <StatusProperties status={status} />}
          {children}
        </div>
      </div>
    </div>
  )
}

export default Section
