import { useDispatch } from 'react-redux'
import useModerationState from './useModerationState'
import { setLocal } from '../../../../../../redux/action/local'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import getTOP from './helper/getTOP'
import { moderationStateProp } from '../../../../../../types/Versammlung/Moderation'

type Result = {
  protokoll: string
  setProtokoll: (protokoll: string) => void
}

const useProtokoll = (): Result => {
  const dispatch = useDispatch()
  const moderationState = useModerationState()
  const [protokoll, setProtokoll] = useState<string>()

  useEffect(() => {
    if (!moderationState) return
    const ergebnis = getTOP(moderationState)
    if (ergebnis) {
      setProtokoll(ergebnis.ergebnisTOP.protokoll)
    }
  }, [moderationState])

  const set = (text: string): void => {
    if (!moderationState) return
    const ergebnis = getTOP(moderationState)
    const newErgebnis = _.cloneDeep(ergebnis.ergebnisTOP)
    newErgebnis.protokoll = text
    const newState = {
      ...moderationState,
      versammlungErgebnis: {
        ...moderationState.versammlungErgebnis,
        tagesordnungspunkte: moderationState.versammlungErgebnis.tagesordnungspunkte.map((t) =>
          t.key === ergebnis.ergebnisTOP.key ? newErgebnis : t,
        ),
      },
    }
    dispatch(setLocal(moderationStateProp, newState))
  }

  return { protokoll, setProtokoll: set }
}

export default useProtokoll
