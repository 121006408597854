import { useDispatch } from 'react-redux'
import { Beteiligung } from '../../../../../../types/Beteiligung'
import useModerationState from './useModerationState'
import { setLocal } from '../../../../../../redux/action/local'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import getErgebnis from './helper/getErgebnis'
import { Abstimmung } from '../../../../../../types/Versammlung/VersammlungErgebnis'
import { moderationStateProp } from '../../../../../../types/Versammlung/Moderation'

type Result =
  | {
      set: (abstimmung: Abstimmung) => void
      abstimmung: Abstimmung
    }
  | false

const useSetAbstimmung = (b: Beteiligung): Result => {
  const dispatch = useDispatch()
  const moderationState = useModerationState()
  const [abstimmung, setAbstimmung] = useState<Abstimmung>()

  useEffect(() => {
    if (!moderationState) return
    const ergebnis = getErgebnis(b, moderationState)
    if (ergebnis) {
      setAbstimmung(ergebnis?.abstimmung)
    }
  }, [moderationState, b])

  const set = (abstimmung: Abstimmung): void => {
    const newState = _.cloneDeep(moderationState)
    if (!newState) return
    const ergebnis = getErgebnis(b, newState)
    ergebnis.abstimmung = abstimmung
    dispatch(setLocal(moderationStateProp, newState))
  }

  return { set, abstimmung }
}

export default useSetAbstimmung
