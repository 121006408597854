import { NavigationItem } from '../../../types'

type Props = {
  data: NavigationItem
  className?: string
  onClick?: () => void
  mobile?: boolean
}

const Default: React.FC<Props> = ({ data, className = '', onClick = null }) => {
  const clicked = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div className={`flex flex-row gap-1 items-center`} onClick={clicked}>
      <a href={data.link || ''} className={`lg:text-blue no-underline hover:underline hover:bg-opacity-20 py-1 lg:px-3 rounded-full ${className}`}>
        {data.name}
      </a>
    </div>
  )
}

export default Default
