type Props = {
  id: string
  value: string
  onChange: (value: string) => void
  options: { label: string; value: string }[]
}

const RadioGroup: React.FC<Props> = ({ id, value, onChange, options }) => {
  return (
    <div className="flex flex-col gap-1">
      {options.map((option) => (
        <div key={option.value} className="flex flex-row gap-1 items-center">
          <input
            type="radio"
            id={id + option.value}
            name={id}
            value={option.value}
            checked={value === option.value}
            onChange={() => onChange(option.value)}
          />
          <label htmlFor={id + option.value}>{option.label}</label>
        </div>
      ))}
    </div>
  )
}

export default RadioGroup
