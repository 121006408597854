import props from '../../../../../../redux/props'
import { useSelector } from 'react-redux'
import { SectionProps } from '../../Upsert'
import { Beschlussfaehigkeit as BeschlussfaehigkeitType } from '../../../../../../types/Company'
import Toggle from '../../../../../UI/Toggle/Toggle'
import Section from '../../../../../UI/Accordion/Section/Section'
import Textarea from '../../../../../UI/Textarea/Textarea'
import Number from '../../../../../UI/Input/Number'
import Add from './Add'
import { Person } from '../../../../../../types/Person'
import PersonList from '../../../../../UI/PersonList/PersonList'
import Currency from '../../../../../UI/Input/Currency'
import { formatEUR, formatNumber } from '../../../../../../utility'

const Beschlussfaehigkeit: React.FC<SectionProps<BeschlussfaehigkeitType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof BeschlussfaehigkeitType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  const setBestimmteKomplementaere = (personList: Person[]) => {
    const uuidList = personList.map((person) => person.uuid)
    const newData: Pick<BeschlussfaehigkeitType, 'bestimmteKomplementaere' | 'bestimmteKomplementaerePersonList'> = {
      ...data,
      bestimmteKomplementaere: uuidList,
      bestimmteKomplementaerePersonList: personList,
    }
    set(newData)
  }

  const getBestimmteKomplementaerePersonListLabel = () => {
    const personAmount = data.bestimmteKomplementaerePersonList.length
    if (personAmount === 0) {
      return `0 ${t.generic.personen}`
    } else if (personAmount === 1) {
      return `1 ${t.generic.person}`
    } else {
      return `${personAmount} ${t.generic.personen}`
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <Toggle label={t.attributes.keineVoraussetzungen} value={data['keineVoraussetzungen']} onChange={internalSet('keineVoraussetzungen')} />
      {!data['keineVoraussetzungen'] && (
        <>
          <Section title={`${t.company.beschlussfaehigkeit.sections.minKapital}${formatEUR(data.minKapital)}`} light>
            <Currency label placeholder={t.attributes.minKapital} value={data.minKapital} onChange={internalSet('minKapital')} />
          </Section>
          <Section title={`${t.company.beschlussfaehigkeit.sections.minKommanditisten}${formatNumber(data.minKommanditisten)}`} light>
            <Number label placeholder={t.attributes.minKommanditisten} value={data.minKommanditisten} onChange={internalSet('minKommanditisten')} />
          </Section>
          <Section title={`${t.company.beschlussfaehigkeit.sections.minKomplementaere}${formatNumber(data.minKomplementaere)}`} light>
            <Number label placeholder={t.attributes.minKomplementaere} value={data.minKomplementaere} onChange={internalSet('minKomplementaere')} />
          </Section>
          <Section title={`${t.company.beschlussfaehigkeit.sections.minStimmen}${formatNumber(data.minStimmen)}`} light>
            <Number label placeholder={t.attributes.minStimmen} value={data.minStimmen} onChange={internalSet('minStimmen')} />
          </Section>
          <Section title={`${t.company.beschlussfaehigkeit.sections.bestimmteKomplementaere}${getBestimmteKomplementaerePersonListLabel()}`} light>
            <PersonList list={data.bestimmteKomplementaerePersonList} />
            <div className="flex items-center justify-end">
              <Add data={data.bestimmteKomplementaerePersonList} set={setBestimmteKomplementaere} />
            </div>
          </Section>
        </>
      )}
      <Section title={t.company.beschlussfaehigkeit.sections.hinweis} light>
        <Textarea placeholder={t.attributes.hinweis} value={data.hinweis} onChange={internalSet('hinweis')} />
      </Section>
    </div>
  )
}

export default Beschlussfaehigkeit
