enum Routes {
  GET_FILE_UPLOAD_PRESIGNED_URL = 'GET_FILE_UPLOAD_PRESIGNED_URL',

  COMPANY_CREATE = 'COMPANY_CREATE',
  COMPANY_LIST = 'COMPANY_LIST',
  PERSON_CREATE = 'PERSON_CREATE',
  PERSON_LIST = 'PERSON_LIST',
  BETEILIGUNG_LIST = 'BETEILIGUNG_LIST',
  UEBERTRAGUNG_CREATE = 'UEBERTRAGUNG_CREATE',
  VERSAMMLUNG_TEST_MAIL = 'VERSAMMLUNG_TEST_MAIL',
  VERSAMMLUNG_SEND_FREIGABE_MAIL = 'VERSAMMLUNG_SEND_FREIGABE_MAIL',
  VERSAMMLUNG_SAVE = 'VERSAMMLUNG_SAVE',
  VERSAMMLUNG_SAVE_END_DATE = 'VERSAMMLUNG_SAVE_END_DATE',
  VERSAMMLUNG_FINALIZE = 'VERSAMMLUNG_FINALIZE',
  VERSAMMLUNG_GET_PROTOCOL = 'VERSAMMLUNG_GET_PROTOCOL',
}

export default Routes
