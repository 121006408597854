import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import { useState } from 'react'
import Modal from '../../../../../../UI/Modal/Modal'
import Button from '../../../../../../UI/Button/Button'
import { Versammlung } from '../../../../../../../types/Versammlung/Versammlung'

type Props = {
  data: Versammlung['freigabe']
  set: (key: keyof Versammlung['freigabe']) => (value: any) => void
}

const ManualFreigabe: React.FC<Props> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => setOpen(!open)

  const confirm = () => {
    set('status')(true)
    setOpen(false)
  }

  return (
    <div className="border-t border-gray flex justify-end">
      <Button text={t.versammlung.upsert.freigabe.manual.cta} onClick={toggle} className="mt-2" />
      <Modal onConfirm={confirm} show={open} onClose={toggle} title={t.versammlung.upsert.freigabe.manual.title}>
        {t.versammlung.upsert.freigabe.manual.hint}
      </Modal>
    </div>
  )
}

export default ManualFreigabe
