import { SectionType } from '../types'
import Accordion from '../../../../../../UI/Accordion/Accordion'
import { getUUID } from '../../../../../../../utility'
import Single from './Single'
import Button from '../../../../../../UI/Button/Button'
import props from '../../../../../../../redux/props'
import { useSelector } from 'react-redux'
import { Tagesordnungspunkt, TagesordnungspunktType } from '../../../../../../../types/Versammlung/Versammlung'
import { useState } from 'react'

const Tagesordnungspunkte: React.FC<SectionType> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const indexHandler = useState<number>(null)
  const [, setCurrentIndex] = indexHandler

  const add = () => {
    const top: Tagesordnungspunkt = {
      key: getUUID(),
      type: TagesordnungspunktType.VOTING_DEFAULT,
    }
    const topList = Array.isArray(data.tagesordnungspunkte) ? [...data.tagesordnungspunkte, top] : [top]
    set('tagesordnungspunkte')(topList)
    setCurrentIndex(topList.length - 1)
  }

  const remove = (key: string) => () => {
    const newTops = data.tagesordnungspunkte.filter((top: Tagesordnungspunkt) => top.key !== key)
    set('tagesordnungspunkte')(newTops)
  }

  const setInternal = (key: string) => (topKey: keyof Tagesordnungspunkt) => (value: any) => {
    const newTops = data.tagesordnungspunkte.map((top: Tagesordnungspunkt) => {
      if (top.key === key) {
        return { ...top, [topKey]: value }
      }
      return top
    })
    set('tagesordnungspunkte')(newTops)
  }

  const getItems = () =>
    data.tagesordnungspunkte.map((item: Tagesordnungspunkt, i: number) => {
      return {
        title: `${i + 1}. ${item.beschreibung || 'TOP'}`,
        id: item.key,
        data: item,
        content: <Single company={company} item={item} set={setInternal(item.key)} remove={remove(item.key)} />,
      }
    })

  const topCallback = (items: any) => {
    set('tagesordnungspunkte')(items)
  }

  return (
    <div>
      <div className="flex flex-col gap-1">
        <Accordion draggableCallback={topCallback} indexHandler={indexHandler} secondary items={getItems()} />
        <div className="flex justify-end">
          <Button onClick={add} text={t.versammlung.upsert.addTOPCTA} />
        </div>
      </div>
    </div>
  )
}

export default Tagesordnungspunkte
