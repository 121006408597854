import _ from 'lodash'
import { Company } from '../../../../../types/Company'
import { StatusObject } from '../../../../UI/Accordion/Section/StatusBell'
import { bank, logo, mehrheit, base, steuer, kapitalisierung, administratoren, einladungsfrist } from './propertyLists'
import { Property, Required, ValidationProp } from './types'

type ReturnFunction = (prop: ValidationProp) => StatusObject

const useStatusValidator = (data: Company): ReturnFunction => {
  const checkValue = (value: any): boolean => {
    if (Array.isArray(value)) {
      return value.length === 0
    } else {
      return value === '' || value === undefined || value === null || value === 0
    }
  }

  const validate = (baseProp: string, properties: Property[]): StatusObject => {
    const missingNEEDED = properties.filter(
      (property) => property.required === Required.NEEDED && checkValue(_.get(data, `${baseProp}.${property.name}`)),
    )
    const missingOPTIONAL = properties.filter(
      (property) => property.required === Required.OPTIONAL && checkValue(_.get(data, `${baseProp}.${property.name}`)),
    )
    return {
      missingNEEDED: missingNEEDED.map((property) => property.name),
      missingOPTIONAL: missingOPTIONAL.map((property) => property.name),
    }
  }

  if (!data) {
    return (prop): StatusObject => ({ missingNEEDED: [], missingOPTIONAL: [] })
  }
  return (prop): StatusObject => {
    switch (prop) {
      case ValidationProp.BASE:
        return validate('base', base)
      case ValidationProp.STEUER:
        return validate('steuer', steuer)
      case ValidationProp.KAPITALISIERUNG:
        return validate('kapitalisierung', kapitalisierung)
      case ValidationProp.BANK:
        return validate('bank', bank)
      case ValidationProp.LOGO:
        return validate('logo', logo)
      case ValidationProp.MEHRHEIT:
        return validate('mehrheit', mehrheit)
      case ValidationProp.ADMINISTRATOREN:
        return validate('administratoren', administratoren)
      case ValidationProp.EINLADUNGSFRIST:
        return validate('einladungsfrist', einladungsfrist)
    }
  }
}

export default useStatusValidator
