import { Enthaltung, Mehrheit as CustomMehrheit } from './Mehrheit'
import { Kontakt, Person } from './Person'
import { File } from './generic'

export type Dynamic = {
  kapitalGesamt?: number
  stimmenGesamt?: number
  kapitalVerwendet?: number
  stimmenVerwendet?: number
}

export enum Rechtsform {
  KG = 'KG',
  GMBH = 'GMBH',
  GMBH_KG = 'GMBH_KG',
  AG = 'AG',
  UG = 'UG',
  OHG = 'OHG',
  GBR = 'GBR',
  JPDOER = 'JPDOER',
}

export type Base = {
  name?: string
  rechtsform?: Rechtsform
  strasseHausnummer?: string
  postleitzahl?: string
  stadt?: string
  land?: string
  telefon?: string
  mobilTelefon?: string
  mail?: string
  onliner?: boolean
  externallyManaged?: boolean
}
export type Steuer = {
  steuernummer?: string
  umsatzsteuerID?: string
}
export type Kapitalisierung = {
  initialKapital?: number
  stimmenGesamt?: number
}
export type Bank = {
  iban?: string
  bic?: string
}

export type Kontaktperson = Pick<Kontakt, 'vorname' | 'nachname' | 'mail' | 'telefon'>

export type Kontaktpersonen = {
  internal?: string[]
  internalResolved?: Person[]
  external?: Kontaktperson[]
}

export type Logo = {
  key?: string
  url?: string
}

export type Mehrheit = {
  enthaltungenImSchriftlichenAbstimmungsverfahren?: Enthaltung
  enthaltungenBeiGesellschafterversammlungen?: Enthaltung
  custom?: CustomMehrheit[]
}

export type Beschlussfaehigkeit = {
  keineVoraussetzungen?: boolean
  minKapital?: number
  minKommanditisten?: number
  minKomplementaere?: number
  minStimmen?: number
  bestimmteKomplementaere?: string[]
  bestimmteKomplementaerePersonList?: Person[]
  hinweis?: string
}

export type KapitalerhoehungEinzeln = {
  erhoehungsbetrag: number
  erhoehungDerStimmen: number
  anfangszeitpunkt: Date
  files: File[]
}

export type Kapitalerhoehung = {
  list?: KapitalerhoehungEinzeln[]
}

export type Insolvenz = {
  aktiv?: boolean
  anfangszeitpunkt?: Date
  files?: File[]
}

export type Administratoren = {
  primaer?: string[]
  primaerResolved?: Person[]
  sekundaer?: string[]
  sekundaerResolved?: Person[]
}

export type Einladungsfrist = {
  inTagen?: number
}

export type Inaktivitaet = {
  inaktivitaet?: boolean
}

export type Company = {
  uuid?: string
  password?: string
  dynamic?: Dynamic
  base: Base
  steuer: Steuer
  kapitalisierung: Kapitalisierung
  bank: Bank
  kontaktpersonen: Kontaktpersonen
  logo: Logo
  mehrheit: Mehrheit
  beschlussfaehigkeit: Beschlussfaehigkeit
  kapitalerhoehung: Kapitalerhoehung
  insolvenz: Insolvenz
  administratoren: Administratoren
  einladungsfrist: Einladungsfrist
  inaktivitaet: Inaktivitaet
}
