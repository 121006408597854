import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import Button from '../../../../../UI/Button/Button'
import Modal from '../../../../../UI/Modal/Modal'
import { Company, KapitalerhoehungEinzeln } from '../../../../../../types/Company'
import Number from '../../../../../UI/Input/Number'
import Input from '../../../../../UI/Input/Input'
import Currency from '../../../../../UI/Input/Currency'
import { UPLOAD_TYPES } from '../../../../../UI/DirectFileUpload/DirectFileUpload'
import AddFiles from '../../../../../UI/AddFiles/AddFiles'
import { File } from '../../../../../../types/generic'

type Props = {
  company: Company
  initData?: KapitalerhoehungEinzeln
  upsert: (k: KapitalerhoehungEinzeln) => void
  children?: React.ReactNode
}

const Add: React.FC<Props> = ({ company, upsert, initData = {} as KapitalerhoehungEinzeln, children }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)
  const [data, setData] = useState<KapitalerhoehungEinzeln>(initData)

  const set = (prop: keyof KapitalerhoehungEinzeln) => (value: any) => {
    setData({ ...data, [prop]: value })
  }

  const addFiles = (files: File[]) => {
    const newFiles = Array.isArray(data.files) ? [...data.files, ...files] : files
    set('files')(newFiles)
  }

  const removeFile = (file: File) => () => {
    const newFiles = data.files.filter((f) => f.key !== file.key)
    set('files')(newFiles)
  }

  const confirm = () => {
    upsert(data)
    toggle()
  }

  return (
    <>
      {children ? (
        <div className="cursor-pointer" onClick={toggle}>
          {children}
        </div>
      ) : (
        <Button onClick={toggle} text={t.company.kapitalerhoehung.add.cta} />
      )}
      <Modal large onConfirm={confirm} show={open} onClose={toggle} title={t.company.kapitalerhoehung.add.title}>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col md:flex-row gap-3">
            <Currency
              disabled
              label
              placeholder={t.company.kapitalerhoehung.add.aktuelleKapitalisierung}
              value={company.dynamic?.kapitalGesamt}
              onChange={() => {}}
            />
            <Number
              disabled
              label
              placeholder={t.company.kapitalerhoehung.add.aktuelleStimmenanzahl}
              value={company.dynamic?.stimmenGesamt}
              onChange={() => {}}
            />
          </div>
          <Currency required label placeholder={t.attributes.erhoehungsbetrag} value={data.erhoehungsbetrag} onChange={set('erhoehungsbetrag')} />
          <Number
            required
            label
            placeholder={t.attributes.erhoehungDerStimmen}
            value={data.erhoehungDerStimmen}
            onChange={set('erhoehungDerStimmen')}
          />
          <Input
            required
            type="date"
            label
            placeholder={t.attributes.anfangszeitpunkt}
            value={data.anfangszeitpunkt}
            onChange={set('anfangszeitpunkt')}
          />
          <AddFiles
            id={company.uuid}
            files={data.files}
            onFiles={addFiles}
            removeCallback={removeFile}
            uploadType={UPLOAD_TYPES.COMPANY_KAPITALERHOEHUNG}
          />
        </div>
      </Modal>
    </>
  )
}

export default Add
