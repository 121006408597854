import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import useIsBeschlussfaehig from '../../hooks/useIsBeschlussfaehig'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom'

const Beschlussfaehigkeit: React.FC<{ topIndex: number }> = ({ topIndex }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const isBeschlussfaehig = useIsBeschlussfaehig(topIndex)
  const { type } = useParams()

  return (
    <div>
      <div className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">
        {t.versammlung.moderate.beschlussfaehigkeit.title}
      </div>
      <div className="flex flex-col gap-1 mt-5 items-center">
        {isBeschlussfaehig ? (
          <CheckCircleIcon className={'fill-green w-16 cursor-pointer'} />
        ) : (
          <XCircleIcon className={'fill-red w-16 cursor-pointer'} />
        )}
        <div className="text-center">
          {t.versammlung.moderate.beschlussfaehigkeit.prefix(t.versammlung[type])}
          <span className="font-bold">{t.versammlung.moderate.beschlussfaehigkeit.state[isBeschlussfaehig ? 'yes' : 'no']}</span>
          {t.versammlung.moderate.beschlussfaehigkeit.suffix}
        </div>
      </div>
    </div>
  )
}

export default Beschlussfaehigkeit
