import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { InputWrapper, SectionProps } from '../Upsert'
import IbanInput from '../../../../UI/Input/IbanInput'
import Input from '../../../../UI/Input/Input'
import { Bank as BankType } from '../../../../../types/Company'

const Bank: React.FC<SectionProps<BankType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof BankType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <InputWrapper>
        <IbanInput label={t.attributes.iban} value={data['iban']} onChange={internalSet('iban')} />
      </InputWrapper>
      <InputWrapper>
        <Input label placeholder={t.attributes.bic} value={data['bic']} onChange={internalSet('bic')} />
      </InputWrapper>
    </div>
  )
}

export default Bank
