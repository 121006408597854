import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import Button from '../../../../../UI/Button/Button'
import Modal from '../../../../../UI/Modal/Modal'
import { SectionProps } from '../../Upsert'
import { Kontaktperson, Kontaktpersonen } from '../../../../../../types/Company'
import ContactPersonSelect from '../../../../../UI/ContactPersonSelect/ContactPersonSelect'
import ManualPersonList from './ManualPersonList'
import { Person } from '../../../../../../types/Person'

const Add: React.FC<SectionProps<Kontaktpersonen>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const [internalList, setInternalList] = useState<Person[]>(data.internalResolved || [])
  const [manualList, setManualList] = useState<Kontaktperson[]>(data.external || [])

  const toggle = () => setOpen(!open)

  const confirm = async () => {
    const newData: Kontaktpersonen = {
      internal: internalList.map((i) => i.uuid),
      internalResolved: internalList,
      external: manualList,
    }
    set(newData)
    toggle()
  }

  return (
    <>
      <Button onClick={toggle} text={t.company.kontaktpersonen.add.cta} />
      <Modal noForm large onConfirm={confirm} show={open} onClose={toggle} title={t.company.kontaktpersonen.add.title}>
        <div className="flex flex-col md:flex-row gap-10 md:gap-3 mt-3">
          <div className="flex-1">
            <ContactPersonSelect companyUUID={company?.uuid} selected={internalList} onSelect={setInternalList} />
          </div>
          <div className="flex-1">
            <ManualPersonList data={manualList} set={setManualList} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Add
