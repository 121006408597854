import { Beteiligung } from '../../../../../../../types/Beteiligung'
import { ActiveTOPEnum, ModerationState } from '../../../../../../../types/Versammlung/Moderation'
import { Ergebnis } from '../../../../../../../types/Versammlung/VersammlungErgebnis'

const getErgebnis = (b: Beteiligung, moderationState: ModerationState): Ergebnis => {
  const { versammlungErgebnis, activeTOP } = moderationState
  const topNumber = activeTOP === ActiveTOPEnum.START ? 0 : activeTOP
  const top = versammlungErgebnis.tagesordnungspunkte[topNumber]
  const ergebnis = top.ergebnisse.find((e: Ergebnis) => e.beteiligungUUID === b.uuid)
  return ergebnis
}

export default getErgebnis
