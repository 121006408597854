import Start from './Start'
import TOP from './TOP'
import End from './End'
import Navigate from './Navigate'
import useModerationState from '../hooks/useModerationState'
import { ActiveTOPEnum } from '../../../../../../types/Versammlung/Moderation'

const TOPNavigator: React.FC<{}> = () => {
  const moderationState = useModerationState()
  if (!moderationState) return <div></div>
  const { activeTOP } = moderationState

  return (
    <>
      {activeTOP === ActiveTOPEnum.START && <Start />}
      {activeTOP === ActiveTOPEnum.END && <End />}
      {typeof activeTOP === 'number' && <TOP />}
      {activeTOP !== ActiveTOPEnum.START && <Navigate />}
    </>
  )
}

export default TOPNavigator
