import LargeContainer from '../../../../UI/Container/LargeContainer'
import { BuildingOffice2Icon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import Loading from '../../../../UI/Loading/Loading'
import { useParams } from 'react-router-dom'
import { getPrettyDateTime } from '../../../../../utility'
import TOPNavigator from './TOPNavigator/TOPNavigator'
import useModerationState from './hooks/useModerationState'

const Moderate = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { companyUUID, versammlungUUID } = useParams()
  const moderationState = useModerationState(companyUUID, versammlungUUID)

  if (!moderationState) {
    return (
      <div className="flex flex-col items-center">
        <Loading loading={false} />
        <div className="text-sm">{t.versammlung.moderate.loading}</div>
      </div>
    )
  }
  return (
    <LargeContainer>
      <div className="mt-10 pb-20">
        <div className="overflow-hidden flex gap-3 items-center justify-start">
          <BuildingOffice2Icon className="fill-blue w-10 shrink-0" />
          <div className="font-bold text-lg text-blue truncate">{`${moderationState.company.base.name} - ${
            t.versammlung[moderationState.versammlung.type]
          } - ${getPrettyDateTime(moderationState.versammlung.datumUhrzeit)}`}</div>
        </div>
        <TOPNavigator />
      </div>
    </LargeContainer>
  )
}
export default Moderate
