import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useEffect, useState } from 'react'
import Toggle from '../Toggle/Toggle'
import Input from '../Input/Input'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import Checkbox from '../Checkbox/Checkbox'
import Loading from '../Loading/Loading'
import { Person } from '../../../types/Person'

type Props = {
  companyUUID?: string
  selected: Person[]
  onSelect: (personList: Person[]) => void
}

const ContactPersonSelect: React.FC<Props> = ({ companyUUID, selected = [], onSelect }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [enableGlobalSearch, setEnableGlobalSearch] = useState<boolean>(!companyUUID)
  const [search, setSearch] = useState<string>('')
  const [personList, setPersonList] = useState<Person[]>()
  const list = functional.use(Routes.PERSON_LIST)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const pull = async () => {
      setLoading(true)
      const result: Person[] = await list({ criteria: { companyUUID: !enableGlobalSearch ? companyUUID : undefined } })
      setLoading(false)
      if (Array.isArray(result)) {
        setPersonList(result)
      }
    }
    if (!personList) {
      pull()
    }
  }, [companyUUID, personList, list, enableGlobalSearch])

  const getName = (person: Person) => {
    return `${person.kontakt.vorname} ${person.kontakt.nachname}`
  }

  const filter = (person: Person) => {
    if (search) {
      return getName(person).toLowerCase().includes(search.toLowerCase())
    }
    return true
  }

  const isSelected = (person: Person) => !!selected.find((p) => p.uuid === person.uuid)

  const select = (person: Person) => () => {
    if (isSelected(person)) {
      onSelect(selected.filter((p) => p.uuid !== person.uuid))
    } else {
      onSelect([...selected, person])
    }
  }

  const toggle = () => {
    setEnableGlobalSearch(!enableGlobalSearch)
    setSearch('')
    setPersonList(undefined)
  }

  return (
    <div className="w-full flex flex-col gap-1">
      <Input label placeholder={t.UI.contactPersonSelect.search} value={search} onChange={setSearch} />
      {!!companyUUID && <Toggle label={t.UI.contactPersonSelect.toggleLabel} value={enableGlobalSearch} onChange={toggle} />}
      <div className="flex flex-col gap-1 max-h-32 overflow-y-auto overflow-x-hidden">
        {!loading &&
          Array.isArray(personList) &&
          personList
            .filter(filter)
            .map((person) => <Checkbox key={person.uuid} label={getName(person)} checked={isSelected(person)} onChange={select(person)} />)}
        <Loading loading={!loading} />
      </div>
    </div>
  )
}

export default ContactPersonSelect
