import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { SectionProps } from '../Upsert'
import Toggle from '../../../../UI/Toggle/Toggle'
import { Insolvenz as InsolvenzType } from '../../../../../types/Company'
import AddFiles from '../../../../UI/AddFiles/AddFiles'
import { UPLOAD_TYPES } from '../../../../UI/DirectFileUpload/DirectFileUpload'
import Input from '../../../../UI/Input/Input'
import { File } from '../../../../../types/generic'

const Insolvenz: React.FC<SectionProps<InsolvenzType>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof InsolvenzType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  const removeFile = (file: File) => () => {
    const newFiles = data.files.filter((f) => f.key !== file.key)
    internalSet('files')(newFiles)
  }

  return (
    <div className="flex flex-col gap-3">
      <Toggle label={t.attributes.insolvenzAktiv} value={data.aktiv} onChange={internalSet('aktiv')} />
      {!!data.aktiv && (
        <div className="flex flex-col gap-3">
          <Input
            required
            type="date"
            label
            placeholder={t.attributes.anfangszeitpunkt}
            value={data.anfangszeitpunkt}
            onChange={internalSet('anfangszeitpunkt')}
          />
          <AddFiles
            id={company.uuid}
            removeCallback={removeFile}
            files={data.files}
            onFiles={internalSet('files')}
            uploadType={UPLOAD_TYPES.COMPANY_INSOLVENZ}
          />
        </div>
      )}
    </div>
  )
}

export default Insolvenz
