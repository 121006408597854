import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../redux/props'
import { Beteiligung } from '../../../types/Beteiligung'
import { useCallback, useEffect, useState } from 'react'
import useToast, { Type } from '../../hooks/useToast'
import { useSelector } from 'react-redux'
import List from './List'
import Upsert from './Upsert'

type CompanyProps = {
  companyUUID: string
  personUUID?: never
}
type PersonProps = {
  personUUID: string
  companyUUID?: never
}

const BeteiligungManager: React.FC<CompanyProps | PersonProps> = ({ personUUID, companyUUID }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const beteiligungFeature = crud.use(props.BETEILIGUNG)
  const [beteiligungen, setBeteiligungen] = useState<Beteiligung[]>()
  const toast = useToast()

  const pull = useCallback(async () => {
    const query = personUUID ? { personUUID } : { companyUUID }
    const beteiligungen = await beteiligungFeature.list(query)
    if (Array.isArray(beteiligungen)) {
      setBeteiligungen(beteiligungen)
    } else {
      toast(t.UI.beteiligungManager.error.list, Type.ERROR)
    }
  }, [personUUID, companyUUID, beteiligungFeature, toast, t])

  useEffect(() => {
    if (!Array.isArray(beteiligungen)) pull()
  }, [beteiligungen, pull])

  const updateList = (isUpdate: boolean) => (beteiligung: Beteiligung) => {
    if (isUpdate) {
      setBeteiligungen((prev) => prev.map((i) => (i.uuid === beteiligung.uuid ? beteiligung : i)))
    } else {
      setBeteiligungen((prev) => [...prev, beteiligung])
    }
  }

  if (!Array.isArray(beteiligungen)) return <></>
  return (
    <div>
      <List beteiligungen={beteiligungen} callback={updateList(true)} pullList={pull} />
      <div className="flex justify-end mt-3">
        <Upsert personUUID={personUUID} companyUUID={companyUUID} callback={updateList(false)} pullList={pull} />
      </div>
    </div>
  )
}

export default BeteiligungManager
