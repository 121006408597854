import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { FreigabeState } from '../../../types/Versammlung/Versammlung'

type Props = {
  status: FreigabeState
}

const FreigabeStatusIndicator: React.FC<Props> = ({ status }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (typeof status === 'undefined' || status === null) return <></>

  const getColor = () => {
    if (status === FreigabeState.DECLINED) {
      return 'bg-red'
    } else if (status === FreigabeState.PENDING) {
      return 'bg-gray'
    } else if (status === FreigabeState.ACCEPTED) {
      return 'bg-green'
    }
  }

  return (
    <div className="flex flex-row gap-1 shrink-0 items-center">
      <div className="shrink-0">{t.attributes.freigabeStatus[status]}</div>
      <div className={`shrink-0 w-4 aspect-square rounded-full ${getColor()}`}></div>
    </div>
  )
}

export default FreigabeStatusIndicator
