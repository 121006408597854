import { useSelector } from 'react-redux'
import { StatusObject } from './StatusBell'
import props from '../../../../redux/props'
import _ from 'lodash'

type Props = {
  status: StatusObject
}

const StatusProperties: React.FC<Props> = ({ status }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (!status || typeof status === 'boolean') return <></>
  return (
    <div className="w-full mb-2">
      {status && (
        <div className="">
          {status.missingNEEDED.length > 0 && (
            <div className="text-red">
              {t.UI.accordion.missingProps.neededPrefix}
              {status.missingNEEDED.map((item) => _.get(t.attributes, item)).join(', ')}
            </div>
          )}
          {status.missingOPTIONAL.length > 0 && (
            <div className="text-orange">
              {t.UI.accordion.missingProps.optionalPrefix}
              {status.missingOPTIONAL.map((item) => t.attributes[item]).join(', ')}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default StatusProperties
