import { Abstimmung } from '../../../../../../../../types/Versammlung/VersammlungErgebnis'
import { Tagesordnungspunkt } from '../../../../../../../../types/Versammlung/Versammlung'
import RadioGroup from '../../../../../../../UI/RadioGroup/RadioGroup'

type Props = {
  id: string
  abstimmung: Abstimmung
  set: (abstimmung: Abstimmung) => void
  top: Tagesordnungspunkt
}

const Options: React.FC<Props> = ({ id, abstimmung, set, top }) => {
  return (
    <div className="flex flex-row gap-1">
      <RadioGroup id={id} value={abstimmung} onChange={set} options={top.votingOptions.map((vO) => ({ label: vO.name, value: vO.uuid }))} />
    </div>
  )
}

export default Options
