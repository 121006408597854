import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import props from '../../../redux/props'
import { ArtDerBeteiligung as ArtDerBeteiligungTypes } from '../../../types/Beteiligung'

const ArtDerBeteiligung = ({ value, onChange, required = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Dropdown
      label={t.UI.artDerBeteiligung.title}
      required={required}
      items={Object.keys(ArtDerBeteiligungTypes).map((i) => ({ label: t.attributes.artDerBeteiligung[i], value: i }))}
      value={value}
      onChange={onChange}
    />
  )
}

export default ArtDerBeteiligung
