import Header from './Header/Header'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'
import ScrollToTop from './ScrollToTop/ScrollToTop'
import { AuthLevelRequired } from '../../../types/Auth'

type Props = {
  children: React.ReactNode
  className?: string
  authLevelRequired?: AuthLevelRequired
  noPadding?: boolean
  noHeader?: boolean
  noFooter?: boolean
}

const Layout: React.FC<Props> = ({ children, className, noPadding = false, noHeader = false, noFooter = false, authLevelRequired = [] }) => {
  return (
    <div className={className}>
      <Secure authLevelRequired={authLevelRequired}>
        <ScrollToTop />
        {!noHeader && <Header />}
        <div className={`relative bg-background min-h-screen ${!noPadding && 'pt-16'}`}>{children}</div>
        {!noFooter && <Footer />}
      </Secure>
    </div>
  )
}

export default Layout
