import { Company } from '../Company'
import { Versammlung } from './Versammlung'
import { VersammlungErgebnis } from './VersammlungErgebnis'

export enum ActiveTOPEnum {
  START = 'START',
  END = 'END',
}

export type ActiveTOPState = ActiveTOPEnum | number

export const moderationStateProp = 'moderationState'

export type ModerationState = {
  uuid?: string
  key: string // key is companyUUID + versammlungUUID
  company: Company
  versammlung: Versammlung
  versammlungErgebnis: VersammlungErgebnis
  activeTOP: ActiveTOPState
  isDemoMode: boolean
}
