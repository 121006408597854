export enum ValidationProp {
  LOGIN = 'LOGIN',
  KONTAKT = 'KONTAKT',
  STEUER = 'STEUER',
  INAKTIVITAET = 'INAKTIVITAET',
}

export enum Required {
  NEEDED = 'NEEDED',
  OPTIONAL = 'OPTIONAL',
}

export type Property = {
  name: string
  required: Required
}
