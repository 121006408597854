import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { SectionProps } from '../Upsert'
import { Einladungsfrist as EinladungsfristType } from '../../../../../types/Company'
import Number from '../../../../UI/Input/Number'

const Einladungsfrist: React.FC<SectionProps<EinladungsfristType>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof EinladungsfristType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  return (
    <div className="flex flex-col gap-3">
      <div>{t.company.einladungsfrist.hint}</div>
      <Number required label placeholder={t.attributes.inTagen} value={data.inTagen} onChange={internalSet('inTagen')} />
    </div>
  )
}

export default Einladungsfrist
