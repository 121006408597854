import { useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import props from '../../../../../../redux/props'
import { SectionType } from './types'
import Textarea from '../../../../../UI/Textarea/Textarea'
import { Versammlung } from '../../../../../../types/Versammlung/Versammlung'

const Einladung: React.FC<SectionType> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const setLocal = (key: keyof Versammlung['einladung']) => (value: any) => {
    set('einladung')({ ...data.einladung, [key]: value })
  }

  return (
    <div>
      <div className="flex flex-col lg:flex-row gap-3">
        <div className="flex-1 flex flex-col gap-2">
          <Input label placeholder={t.versammlung.attributes.betreff} value={data.einladung.betreff} onChange={setLocal('betreff')} />
          <Textarea label placeholder={t.versammlung.attributes.anschreiben} value={data.einladung.anschreiben} onChange={setLocal('anschreiben')} />
        </div>
      </div>
    </div>
  )
}

export default Einladung
