import { useEffect, useState } from 'react'
import { Beteiligung } from '../../../types/Beteiligung'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import props from '../../../redux/props'
import { crud, usePrevious } from '@think-internet/zeus-frontend-package'
import { PencilIcon } from '@heroicons/react/24/solid'
import { useSelector } from 'react-redux'
import CompanySelect from '../CompanySelect/CompanySelect'
import { Company, Rechtsform } from '../../../types/Company'
import Input from '../Input/Input'
import Currency from '../Input/Currency'
import Number from '../Input/Number'
import IbanInput from '../Input/IbanInput'
import ArtDerBeteiligung from '../Input/ArtDerBeteiligung'
import Position from '../Input/Position'
import Toggle from '../Toggle/Toggle'
import AddFiles from '../AddFiles/AddFiles'
import { UPLOAD_TYPES } from '../DirectFileUpload/DirectFileUpload'
import { File } from '../../../types/generic'
import useToast, { Type } from '../../hooks/useToast'
import { formatEUR, formatNumber } from '../../../utility'
import _ from 'lodash'
import RemoveConfirmation from '../RemoveConfirmation/RemoveConfirmation'
import { TrashIcon } from '@heroicons/react/24/outline'

type Props = {
  personUUID?: string
  companyUUID?: string
  initData?: Beteiligung
  callback: (beteiligung: Beteiligung) => void
  pullList: () => void
}

type InternalBeteiligung = Beteiligung & { repeatCompanyName?: string; confirmation?: boolean }
type PreviousParameters = {
  personUUID?: string
  companyUUID?: string
  initData?: Beteiligung
}

const Upsert: React.FC<Props> = ({ personUUID, companyUUID, callback, initData = {}, pullList }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<InternalBeteiligung>()
  const [open, setOpen] = useState<boolean>(false)
  const beteiligungFeature = crud.use(props.BETEILIGUNG)
  const toast = useToast()
  const previousParameter: PreviousParameters = usePrevious({ personUUID, companyUUID, initData })

  useEffect(() => {
    if (!data) {
      setData({ personUUID, companyUUID, ...initData })
    } else if (
      !!previousParameter &&
      (previousParameter.personUUID !== personUUID ||
        previousParameter.companyUUID !== companyUUID ||
        !_.isEqual(previousParameter, { personUUID, companyUUID, initData }))
    ) {
      setData({ personUUID, companyUUID, ...initData })
    }
  }, [data, initData, personUUID, companyUUID, previousParameter])

  const confirm = async () => {
    if (data.targetCompany && data.targetCompanyUUID && data.repeatCompanyName) {
      if (data.targetCompany.base.name === data.repeatCompanyName) {
        if (getAvailableKapital() >= 0) {
          if (getAvailableStimmen() >= 0) {
            const result = await beteiligungFeature.upsert(data)
            if (result) {
              callback(result)
              toast(t.UI.beteiligungManager.upsert.success, Type.SUCCESS)
              setOpen(false)
            } else {
              toast(t.UI.beteiligungManager.upsert.error.generic, Type.ERROR)
            }
          } else {
            toast(t.UI.beteiligungManager.upsert.error.notEnoughStimmen, Type.ERROR)
          }
        } else {
          toast(t.UI.beteiligungManager.upsert.error.notEnoughKapital, Type.ERROR)
        }
      } else {
        toast(t.UI.beteiligungManager.upsert.error.companyNameNoMatch, Type.ERROR)
      }
    } else {
      toast(t.UI.beteiligungManager.upsert.error.missingCompany, Type.ERROR)
    }
  }

  const remove = async () => {
    const result = await beteiligungFeature.remove({ uuid: initData.uuid })
    if (result) {
      callback(result)
      toast(t.UI.beteiligungManager.upsert.remove.success, Type.SUCCESS)
      setOpen(false)
      pullList()
    } else {
      toast(t.UI.beteiligungManager.upsert.remove.error, Type.ERROR)
    }
  }

  const toggle = () => setOpen(!open)

  const set = (prop: keyof InternalBeteiligung) => (value: any) => {
    setData({ ...data, [prop]: value })
  }

  const setTargetCompany = (selectedCompanyList: Company[]) => {
    if (selectedCompanyList.length > 0) {
      setData({ ...data, targetCompanyUUID: selectedCompanyList[0].uuid, targetCompany: selectedCompanyList[0] })
    } else {
      setData({ ...data, targetCompanyUUID: null, targetCompany: null })
    }
  }

  const removeFile = (file: File) => () => {
    const newFiles = data.files.filter((f) => f.key !== file.key)
    set('files')(newFiles)
  }

  const getAvailableKapital = () => {
    if (data.targetCompany) {
      if (data.uuid) {
        return (
          data.targetCompany.dynamic.kapitalGesamt -
          (data.targetCompany.dynamic.kapitalVerwendet - (initData.beteiligungsHoehe || 0) + (data.beteiligungsHoehe || 0))
        )
      } else {
        return data.targetCompany.dynamic.kapitalGesamt - data.targetCompany.dynamic.kapitalVerwendet - (data.beteiligungsHoehe || 0)
      }
    }
    return 0
  }

  const getAvailableStimmen = () => {
    if (data.targetCompany) {
      if (data.uuid) {
        return (
          data.targetCompany.dynamic.stimmenGesamt - (data.targetCompany.dynamic.stimmenVerwendet - (initData.stimmen || 0) + (data.stimmen || 0))
        )
      } else {
        return data.targetCompany.dynamic.stimmenGesamt - data.targetCompany.dynamic.stimmenVerwendet - (data.stimmen || 0)
      }
    }
    return 0
  }

  if (!data) return null
  return (
    <>
      {!!initData.uuid ? (
        <div className="flex gap-3 justify-center">
          <RemoveConfirmation onConfirm={remove} text={t.UI.beteiligungManager.upsert.remove.text(initData.targetCompany.base.name)}>
            <TrashIcon className="h-5 w-5 stroke-red cursor-pointer" />
          </RemoveConfirmation>
          <div className="cursor-pointer" onClick={toggle}>
            <PencilIcon className="h-5 w-5 text-blue" />
          </div>
        </div>
      ) : (
        <Button onClick={toggle} text={t.UI.beteiligungManager.upsert.cta} />
      )}
      <Modal
        large
        onConfirm={confirm}
        show={open}
        onClose={toggle}
        title={initData.uuid ? t.UI.beteiligungManager.upsert.updateTitle : t.UI.beteiligungManager.upsert.createTitle}
      >
        <div className="flex flex-col gap-3">
          <CompanySelect
            single
            criteria={{}}
            selected={data.targetCompany ? [data.targetCompany] : []}
            onSelect={setTargetCompany}
            excludedUUIDList={!!data.companyUUID ? [data.companyUUID] : []}
          />
          {data.targetCompany && (
            <>
              <Input
                required
                label
                placeholder={t.UI.beteiligungManager.upsert.repeatCompanyName}
                value={data.repeatCompanyName}
                onChange={set('repeatCompanyName')}
              />
              <Currency
                label
                placeholder={`${t.attributes.beteiligungsHoehe} (verbleibend ${formatEUR(getAvailableKapital())})`}
                value={data.beteiligungsHoehe}
                onChange={set('beteiligungsHoehe')}
              />
              <Number
                label
                placeholder={`${t.attributes.stimmen} (verbleibend ${formatNumber(getAvailableStimmen())})`}
                value={data.stimmen}
                onChange={set('stimmen')}
              />
              <Input
                required={!!data.targetCompany ? data.targetCompany.base.rechtsform !== Rechtsform.GMBH : true}
                label
                placeholder={t.attributes.beteiligungsnummer}
                value={data.beteiligungsnummer}
                onChange={set('beteiligungsnummer')}
              />
              <IbanInput required label={t.attributes.iban} value={data.iban} onChange={set('iban')} />
              <Input required label placeholder={t.attributes.bic} value={data.bic} onChange={set('bic')} />
              <ArtDerBeteiligung required value={data.artDerBeteiligung} onChange={set('artDerBeteiligung')} />
              <Position value={data.position} onChange={set('position')} />
              <Input
                type="date"
                required
                label
                placeholder={t.attributes.anfangszeitpunkt}
                value={data.anfangszeitpunkt}
                onChange={set('anfangszeitpunkt')}
              />
              <div className="flex flex-col md:flex-row items-start gap-3">
                {/* <div className="flex-1 flex flex-col">
                  <Toggle label={t.attributes.entnahmeBerechtigt} value={data.entnahmeBerechtigt} onChange={set('entnahmeBerechtigt')} />
                  <div className="text-sm text-gray mt-1">{t.attributes.entnahmeBerechtigtHint}</div>
                </div> */}
                <div className="flex-1 flex flex-col">
                  <Toggle label={t.attributes.entnahmeGesperrt} value={data.entnahmeGesperrt} onChange={set('entnahmeGesperrt')} />
                  <div className="text-sm text-gray mt-1">{t.attributes.entnahmeGesperrtHint}</div>
                </div>
                {/* <div className="flex-1 flex flex-col">
                  <Toggle label={t.attributes.istBeteiligung} value={data.istBeteiligung} onChange={set('istBeteiligung')} />
                  <div className="text-sm text-gray mt-1">{t.attributes.istBeteiligungHint}</div>
                </div> */}
              </div>
              <AddFiles
                id={data.personUUID || data.companyUUID}
                removeCallback={removeFile}
                uploadType={UPLOAD_TYPES.BETEILIGUNG}
                files={data.files}
                onFiles={set('files')}
              />
              <Toggle required label={t.UI.beteiligungManager.upsert.checkHint} value={data.confirmation} onChange={set('confirmation')} />
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default Upsert
