import { ArtDerBeteiligung } from '../../../../../../types/Beteiligung'
import useModerationState from './useModerationState'
import { useEffect, useState } from 'react'

const useIsBeschlussfaehig = (topIndex: number): boolean => {
  const moderationState = useModerationState()
  const [isBeschlussfaehig, setIsBeschlussfaehig] = useState<boolean>(false)

  useEffect(() => {
    if (!moderationState) return

    if (moderationState.company.beschlussfaehigkeit.keineVoraussetzungen) {
      setIsBeschlussfaehig(true)
    } else {
      const availableBeteiligungen = moderationState.versammlungErgebnis.tagesordnungspunkte[topIndex].ergebnisse.reduce((acc, ergebnis) => {
        if (!!ergebnis.anwesend) {
          acc.push(ergebnis.beteiligungUUID)
        }
        return acc
      }, [])

      let result = true

      const { minKapital, minKommanditisten, minKomplementaere, minStimmen, bestimmteKomplementaere } = moderationState.company.beschlussfaehigkeit

      if (isFinite(minKapital)) {
        // sum up available kapital
        const kapital = moderationState.versammlungErgebnis.absoluteTeilnehmer.reduce((acc, beteiligung) => {
          if (availableBeteiligungen.includes(beteiligung.uuid)) {
            return (acc += beteiligung.beteiligungsHoehe)
          }
          return acc
        }, 0)
        result = result && kapital >= minKapital
      }

      if (isFinite(minKommanditisten)) {
        // count available kommanditisten
        const kommanditistenCount = moderationState.versammlungErgebnis.absoluteTeilnehmer.filter(
          (b) => availableBeteiligungen.includes(b.uuid) && b.artDerBeteiligung === ArtDerBeteiligung.KOMMANDITIST,
        ).length

        result = result && kommanditistenCount >= minKommanditisten
      }

      if (isFinite(minKomplementaere)) {
        // count available komplementaere
        const komplementaereCount = moderationState.versammlungErgebnis.absoluteTeilnehmer.reduce((acc, beteiligung) => {
          if (availableBeteiligungen.includes(beteiligung.uuid) && beteiligung.artDerBeteiligung === ArtDerBeteiligung.KOMPLEMENTAER) {
            return (acc += 1)
          }
          return acc
        }, 0)
        result = result && komplementaereCount >= minKomplementaere
      }

      if (isFinite(minStimmen)) {
        // sum up available stimmen
        const stimmen = moderationState.versammlungErgebnis.absoluteTeilnehmer.reduce((acc, beteiligung) => {
          if (availableBeteiligungen.includes(beteiligung.uuid)) {
            return (acc += beteiligung.stimmen)
          }
          return acc
        }, 0)
        result = result && stimmen >= minStimmen
      }

      if (Array.isArray(bestimmteKomplementaere)) {
        // has all required komplementaere
        const bestimmteKomplementaereAvailable = bestimmteKomplementaere.reduce((acc, komplementaerUUID) => {
          if (acc === false) return false
          return (
            0 <=
            moderationState.versammlungErgebnis.absoluteTeilnehmer.findIndex(
              (beteiligung) => beteiligung.personUUID === komplementaerUUID || beteiligung.companyUUID === komplementaerUUID,
            )
          )
        }, true)
        result = result && bestimmteKomplementaereAvailable
      }

      setIsBeschlussfaehig(result)
    }
  }, [moderationState, topIndex])

  return isBeschlussfaehig
}

export default useIsBeschlussfaehig
