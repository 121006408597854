import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import props from '../../../../../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { setLocal } from '../../../../../../redux/action/local'
import { Beteiligung } from '../../../../../../types/Beteiligung'
import { Company } from '../../../../../../types/Company'
import { Versammlung } from '../../../../../../types/Versammlung/Versammlung'
import { VersammlungErgebnis } from '../../../../../../types/Versammlung/VersammlungErgebnis'
import { ActiveTOPEnum, ModerationState, moderationStateProp } from '../../../../../../types/Versammlung/Moderation'

type UseModerationState = (companyUUID?: string, versammlungUUID?: string) => ModerationState | false

const useModerationState: UseModerationState = (companyUUID, versammlungUUID) => {
  const dispatch = useDispatch()
  const stateKey = companyUUID + versammlungUUID
  const moderationState = useSelector((state) => state[moderationStateProp])
  const companyFeature = crud.use(props.COMPANY)
  const versammlungFeature = crud.use(props.VERSAMMLUNG)
  const beteiligungFeature = crud.use(props.BETEILIGUNG)

  useEffect(() => {
    const init = async () => {
      const company: Company = await companyFeature.get({ uuid: companyUUID })
      const versammlung: Versammlung = await versammlungFeature.get({ uuid: versammlungUUID })

      const beteiligungen: Beteiligung[] = await beteiligungFeature.list({
        targetCompanyUUID: companyUUID,
        istBeteiligung: true,
        anfangszeitpunkt: { $lte: new Date() },
      })
      if (company && versammlung && beteiligungen) {
        if (!!versammlung.ergebnis) {
          const state: ModerationState = {
            key: stateKey,
            company,
            versammlung,
            versammlungErgebnis: versammlung.ergebnis,
            activeTOP: ActiveTOPEnum.START,
            isDemoMode: false,
          }
          dispatch(setLocal(moderationStateProp, state))
        } else {
          const versammlungErgebnis: VersammlungErgebnis = {
            versammlungUUID,
            absoluteTeilnehmer: beteiligungen,
            tagesordnungspunkte: versammlung.tagesordnungspunkte.slice(0, 1).map((t) => ({
              key: t.key,
              ergebnisse: beteiligungen.map((b) => ({
                beteiligungUUID: b.uuid,
                anwesend: true,
              })),
              protokoll: t.protokoll,
            })),
          }
          const state: ModerationState = {
            key: stateKey,
            company,
            versammlung,
            versammlungErgebnis,
            activeTOP: ActiveTOPEnum.START,
            isDemoMode: true,
          }
          dispatch(setLocal(moderationStateProp, state))
        }
      } else {
        dispatch(setLocal(moderationStateProp, false))
      }
    }

    if (!!companyUUID && !!versammlungUUID && (!moderationState || moderationState.key !== stateKey)) {
      init()
    }
  }, [companyUUID, versammlungUUID, moderationState, dispatch, companyFeature, versammlungFeature, beteiligungFeature, stateKey])

  return moderationState || false
}

export default useModerationState
