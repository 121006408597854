import { Route, Routes } from 'react-router-dom'
import List from './List'
import Upsert from './Upsert/Upsert'

const Person = () => {
  return (
    <Routes>
      <Route path={'/:uuid'} element={<Upsert />} />
      <Route path={'/create'} element={<Upsert />} />
      <Route path={'/'} element={<List />} />
    </Routes>
  )
}

export default Person
