import { useEffect, useState } from 'react'
import props from '../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { Beteiligung } from '../../types/Beteiligung'

const useListGesellschafter = (companyUUID: string): Beteiligung[] | false => {
  const [beteiligungen, setBeteiligungen] = useState<Beteiligung[] | false>(null)
  const beteiligungFeature = crud.use(props.BETEILIGUNG)

  useEffect(() => {
    const pull = async () => {
      const beteiligungen: Beteiligung[] = await beteiligungFeature.list({ targetCompanyUUID: companyUUID })
      if (beteiligungen) {
        setBeteiligungen(beteiligungen)
      } else {
        setBeteiligungen(false)
      }
    }
    if (beteiligungen === null && !!companyUUID) {
      pull()
    }
  }, [companyUUID, beteiligungen, beteiligungFeature])

  return beteiligungen
}

export default useListGesellschafter
