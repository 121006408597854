import InputMask from 'react-input-mask'
import { BaseInput } from './Input'

type CustomProps = {
  mask: string
}

const MaskInput: React.FC<BaseInput & CustomProps> = ({ value, onChange, mask, className, label, required }) => {
  const change = (e) => onChange(e.target.value.replaceAll('_', '').replaceAll(' ', ''))

  return (
    <div className={`w-full ${className}`}>
      {label && <label className="text-white text-sm py-1 px-2 rounded-full block mb-1 font-bold bg-turquoise w-full">{label}</label>}
      <InputMask
        required={required}
        mask={mask}
        alwaysShowMask={true}
        value={value}
        onChange={change}
        className={`placeholder-gray w-full py-1 px-2 bg-lightGray text-black rounded-lg focus:outline-none focus:shadow-outline`}
      />
    </div>
  )
}

export default MaskInput
