import { Beteiligung } from '../../../../../../../../types/Beteiligung'
import { Abstimmung } from '../../../../../../../../types/Versammlung/VersammlungErgebnis'
import Toggles from './Toggles'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../../../../redux/props'
import _ from 'lodash'
import useModerationState from '../../../hooks/useModerationState'
import getErgebnis from '../../../hooks/helper/getErgebnis'
import { setLocal } from '../../../../../../../../redux/action/local'
import { moderationStateProp } from '../../../../../../../../types/Versammlung/Moderation'

type Props = {
  absoluteTeilnehmer: Beteiligung[]
}

const Global: React.FC<Props> = ({ absoluteTeilnehmer }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const moderationState = useModerationState()
  const dispatch = useDispatch()

  const set = (abstimmung: Abstimmung) => {
    const newState = _.cloneDeep(moderationState)
    if (!newState) return
    absoluteTeilnehmer.forEach((b) => {
      const ergebnis = getErgebnis(b, newState)
      ergebnis.abstimmung = abstimmung
    })
    dispatch(setLocal(moderationStateProp, newState))
  }

  return (
    <div className="flex flex-row gap-2 items-center justify-between my-1">
      <div className="">{t.versammlung.moderate.votingList.globalVoting.title}</div>
      <Toggles id="global" abstimmung={null} set={set} />
    </div>
  )
}

export default Global
