import { Company } from './Company'
import { Person } from './Person'
import { File } from './generic'

export enum ArtDerBeteiligung {
  KOMPLEMENTAER = 'KOMPLEMENTAER',
  KOMMANDITIST = 'KOMMANDITIST',
  GESELLSCHAFTER = 'GESELLSCHAFTER',
  MITTELBAR_BETEILIGTER = 'MITTELBAR_BETEILIGTER',
}

export enum Position {
  GESCHAEFTSFUEHRER = 'GESCHAEFTSFUEHRER',
  BEIRAT = 'BEIRAT',
  UNDEFINED = 'UNDEFINED',
}

export type Beteiligung = {
  uuid?: string
  personUUID?: string
  person?: Person
  companyUUID?: string
  company?: Company
  targetCompanyUUID?: string
  targetCompany?: Company
  beteiligungsHoehe?: number
  stimmen?: number
  beteiligungsnummer?: string
  iban?: string
  bic?: string
  artDerBeteiligung?: ArtDerBeteiligung
  position?: Position
  anfangszeitpunkt?: Date
  entnahmeBerechtigt?: boolean
  istBeteiligung?: boolean
  entnahmeGesperrt?: boolean
  files?: File[]
}
