import { Route, Routes } from 'react-router-dom'
import Overview from './Overview'
import Upsert from './Upsert/Upsert'
import Moderate from './Moderate/Moderate'

const Versammlung = () => {
  return (
    <Routes>
      <Route path={'/:type/:companyUUID'} element={<Overview />} />
      <Route path={'/:type/upsert/:companyUUID'} element={<Upsert />} />
      <Route path={'/:type/upsert/:companyUUID/:versammlungUUID'} element={<Upsert />} />
      <Route path={'/:type/moderate/:companyUUID/:versammlungUUID'} element={<Moderate />} />

      <Route path={'/'} element={<Overview />} />
    </Routes>
  )
}

export default Versammlung
