import { useSelector } from 'react-redux'
import { Person } from '../../../types/Person'
import props from '../../../redux/props'

type Props = {
  list: Person[]
}

const PersonList: React.FC<Props> = ({ list }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div>
      <div className="flex flex-row gap-10 w-full">
        <label className="w-1/4 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.vorname}</label>
        <label className="w-1/4 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.nachname}</label>
        <label className="w-1/4 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.mail}</label>
        <label className="w-1/4 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.telefon}</label>
      </div>
      <div className="flex flex-col gap-1">
        {Array.isArray(list) && list.length > 0 ? (
          list.map((person, index) => (
            <div className="flex flex-row gap-10 w-full" key={index}>
              <div className="flex-1 shrink-0 truncate">{person.kontakt.vorname}</div>
              <div className="flex-1 shrink-0 truncate">{person.kontakt.nachname}</div>
              <div className="flex-1 shrink-0 truncate">{person.kontakt.mail}</div>
              <div className="flex-1 shrink-0 truncate">{person.kontakt.telefon || person.kontakt.mobilTelefon}</div>
            </div>
          ))
        ) : (
          <div className="mt-2 text-center text-sm">{t.UI.personList.empty}</div>
        )}
      </div>
    </div>
  )
}

export default PersonList
