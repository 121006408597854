import { CheckCircleIcon, MinusCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { Abstimmung, AbstimmungDefault } from '../../../../../../../../types/Versammlung/VersammlungErgebnis'

type Props = {
  abstimmung: Abstimmung
  set: (abstimmung: Abstimmung) => void
}

const Default: React.FC<Props> = ({ abstimmung, set }) => {
  return (
    <div className="flex flex-row gap-1">
      <CheckCircleIcon
        onClick={() => set(AbstimmungDefault.ZUSTIMMUNG)}
        className={`${abstimmung === AbstimmungDefault.ZUSTIMMUNG ? 'fill-green' : 'fill-gray'} w-10 cursor-pointer hover:fill-green`}
      />
      <XCircleIcon
        onClick={() => set(AbstimmungDefault.ABLEHNUNG)}
        className={`${abstimmung === AbstimmungDefault.ABLEHNUNG ? 'fill-red' : 'fill-gray'} w-10 cursor-pointer hover:fill-red`}
      />
      <MinusCircleIcon
        onClick={() => set(AbstimmungDefault.ENTHALTUNG)}
        className={`${abstimmung === AbstimmungDefault.ENTHALTUNG ? 'fill-orange' : 'fill-gray'} w-10 cursor-pointer hover:fill-orange`}
      />
    </div>
  )
}

export default Default
