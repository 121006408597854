import { useSelector } from 'react-redux'
import Input from '../../../../../UI/Input/Input'
import { SectionType } from './types'
import props from '../../../../../../redux/props'
import Checkbox from '../../../../../UI/Checkbox/Checkbox'
import { StandortType } from '../../../../../../types/Versammlung/Versammlung'

const DateTimeLocation: React.FC<SectionType> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const toggleStandortType = (type: StandortType) => (value: boolean) => {
    if (type === StandortType.OFFLINE) {
      if (value === true) {
        if (data.standortType.includes(StandortType.ONLINE)) {
          set('standortType')([StandortType.ONLINE, StandortType.OFFLINE])
        } else {
          set('standortType')([StandortType.OFFLINE])
        }
      } else {
        set('standortType')([StandortType.ONLINE])
      }
    } else if (type === StandortType.ONLINE) {
      if (value === true) {
        if (data.standortType.includes(StandortType.OFFLINE)) {
          set('standortType')([StandortType.ONLINE, StandortType.OFFLINE])
        } else {
          set('standortType')([StandortType.ONLINE])
        }
      } else {
        set('standortType')([StandortType.OFFLINE])
      }
    }
  }

  return (
    <div>
      <div className="flex flex-col lg:flex-row gap-3">
        <div className="flex-1 flex flex-col gap-2">
          <Input
            type="datetime-local"
            label
            placeholder={t.versammlung.attributes.datumUhrzeit}
            value={data.datumUhrzeit}
            onChange={set('datumUhrzeit')}
          />
          {/* <Input
            type="datetime-local"
            label
            placeholder={t.versammlung.attributes.abgabefrist}
            value={data.abgabefrist}
            onChange={set('abgabefrist')}
          /> */}
          <Input label placeholder={t.attributes.inTagen} value={company.einladungsfrist.inTagen} onChange={() => {}} disabled />
          <Checkbox
            checked={data.einladungsfristIgnorieren}
            onChange={set('einladungsfristIgnorieren')}
            label={t.versammlung.attributes.einladungsfristIgnorieren}
          />
        </div>
        <div className="flex-1 flex flex-col gap-2">
          <div className="flex flex-row flex-wrap gap-3">
            <Checkbox
              checked={data.standortType.includes(StandortType.OFFLINE)}
              onChange={toggleStandortType(StandortType.OFFLINE)}
              label={t.versammlung.attributes[StandortType.OFFLINE]}
            />
            <Checkbox
              checked={data.standortType.includes(StandortType.ONLINE)}
              onChange={toggleStandortType(StandortType.ONLINE)}
              label={t.versammlung.attributes[StandortType.ONLINE]}
            />
          </div>
          {data.standortType.includes(StandortType.OFFLINE) && (
            <Input label placeholder={t.versammlung.attributes.standort} value={data.standort} onChange={set('standort')} />
          )}
          {data.standortType.includes(StandortType.ONLINE) && (
            <>
              <Input label placeholder={t.versammlung.attributes.meetingLink} value={data.meetingLink} onChange={set('meetingLink')} />
              <Input label placeholder={t.versammlung.attributes.meetingID} value={data.meetingID} onChange={set('meetingID')} />
              <Input label placeholder={t.versammlung.attributes.meetingPassword} value={data.meetingPassword} onChange={set('meetingPassword')} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default DateTimeLocation
