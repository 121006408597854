import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import props from '../../../redux/props'
import { Position as PositionTypes } from '../../../types/Beteiligung'

const Position = ({ value, onChange, required = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Dropdown
      label={t.UI.position.title}
      required={required}
      items={Object.keys(PositionTypes).map((i) => ({ label: t.attributes.position[i], value: i }))}
      value={value}
      onChange={onChange}
    />
  )
}

export default Position
