type Props = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  onChange?: (value: string) => void
  label?: string | boolean
  value: string
  placeholder?: string
  disabled?: boolean
  className?: string
  rows?: number
  required?: boolean
  id?: string
  minCharacters?: number
  maxCharacters?: number
  useRef?: any
}

const Textarea: React.FC<Props> = ({
  onChange,
  label,
  value,
  placeholder,
  disabled,
  className,
  rows = 3,
  required = false,
  id,
  minCharacters,
  maxCharacters,
  useRef,
}) => {
  return (
    <div className={`w-full ${className}`}>
      {label && (
        <label htmlFor={id} className="text-white text-sm py-1 px-2 rounded-full block mb-1 font-bold bg-turquoise w-full">
          {typeof label === 'string' ? label : placeholder}
        </label>
      )}
      <textarea
        id={id}
        ref={useRef}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        required={required}
        className={`placeholder-gray w-full py-1 px-2 bg-lightGray text-black rounded-lg focus:outline-none focus:shadow-outline`}
        rows={rows}
        minLength={minCharacters}
        maxLength={maxCharacters}
      />
    </div>
  )
}

export default Textarea
