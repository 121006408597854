import props from '../../../../../../redux/props'
import { useSelector } from 'react-redux'
import { SectionProps } from '../../Upsert'
import { KapitalerhoehungEinzeln, Kapitalerhoehung as KapitalerhoehungType } from '../../../../../../types/Company'
import Upsert from './Upsert'
import { getPrettyDate } from '../../../../../../utility'
import { PencilIcon } from '@heroicons/react/24/solid'

const Kapitalerhoehung: React.FC<SectionProps<KapitalerhoehungType>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const add = (k: KapitalerhoehungEinzeln) => set({ list: [...(data.list || []), k] })

  const upsert = (i: number) => (k: KapitalerhoehungEinzeln) => {
    const list = data.list.map((e, j) => (i === j ? k : e))
    set({ list })
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-10 w-full">
        <label className="w-1/3 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.erhoehungsbetrag}</label>
        <label className="w-1/3 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.erhoehungDerStimmen}</label>
        <label className="w-1/3 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.anfangszeitpunkt}</label>
        <div className="w-10 shrink-0 truncate"></div>
      </div>
      <div className="flex flex-col gap-1">
        {data?.list?.length > 0 ? (
          data.list?.map((erhoehung, index) => (
            <div className="flex flex-row gap-10 w-full" key={index}>
              <div className="flex-1 shrink-0 truncate">{erhoehung.erhoehungsbetrag}</div>
              <div className="flex-1 shrink-0 truncate">{erhoehung.erhoehungDerStimmen}</div>
              <div className="flex-1 shrink-0 truncate">{getPrettyDate(erhoehung.anfangszeitpunkt)}</div>
              <div className="w-10 shrink-0 truncate">
                <Upsert company={company} upsert={upsert(index)} initData={erhoehung}>
                  <PencilIcon className="h-5 w-5 text-blue" />
                </Upsert>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-2 text-center text-sm">{t.company.kapitalerhoehung.list.empty}</div>
        )}
      </div>
      <div className="flex items-center justify-end">
        <Upsert company={company} upsert={add} />
      </div>
    </div>
  )
}

export default Kapitalerhoehung
