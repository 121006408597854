import { crud } from '@think-internet/zeus-frontend-package'

const props = {
  ...crud.Features,

  COMPANY: 'COMPANY',
  PERSON: 'PERSON',
  BETEILIGUNG: 'BETEILIGUNG',
  UEBERTRAGUNG: 'UEBERTRAGUNG',
  VERSAMMLUNG: 'VERSAMMLUNG',
  VERSAMMLUNG_ERGEBNIS: 'VERSAMMLUNG_ERGEBNIS',
}

export default props
